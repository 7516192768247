const activatorsSet = new Set<HTMLElement>();

let scrollDisabled = false;

export const disableScroll = (activator: HTMLElement) => {
  activatorsSet.add(activator);

  if (!scrollDisabled && document.body.scrollHeight > window.innerHeight) {
    scrollDisabled = true;

    document.body.style.cssText = `
      position: fixed;
      overflow-y: scroll;
      top: -${window.scrollY}px;
      width: 100%;
    `;
  }
};

export const enableScroll = (activatorOrForce: HTMLElement | true) => {
  if (activatorOrForce === true) {
    activatorsSet.clear();
  } else {
    activatorsSet.delete(activatorOrForce);
  }

  if (scrollDisabled && activatorsSet.size === 0) {
    scrollDisabled = false;

    const top = parseFloat(document.body.style.top || "0") * -1;

    document.body.style.cssText = "";

    window.scrollTo({ behavior: "auto", top });
  }
};
