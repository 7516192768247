import React from "react";
import styled from "styled-components";

import { Breakpoint } from "modules/DesignSystem/breakpoint";
import BaseContainer from "modules/DesignSystem/components/Container";

import { SearchInput as SearchInputBase } from "./SearchInput";

const threshold = 65; // height of input container

const Container = styled(BaseContainer)<{ isHidden: boolean }>`
  --hide-transition-time: 300ms;
  --margin: ${({ isHidden }) => (isHidden ? 0 : 16)}px;
  display: grid;
  grid-template-rows: ${({ isHidden }) => (isHidden ? "0fr" : "1fr")};
  opacity: ${({ isHidden }) => (isHidden ? 0 : 1)};
  transition: grid-template-rows var(--hide-transition-time), opacity 600ms;

  & > * {
    overflow: hidden;
  }
`;

const SearchInput = styled(SearchInputBase)`
  --input-height: 48px;
  margin-bottom: var(--margin);
  transition: margin var(--hide-transition-time);

  @media (min-width: ${Breakpoint.lg}px) {
    display: none;
  }
`;

type Props = React.ComponentProps<typeof SearchInputBase> & {
  onVisibilityChange: (isVisible: boolean) => void;
  isHidden: boolean;
};

export const SearchInputMobile = ({ onVisibilityChange, isHidden, ...props }: Props) => {
  const lastScroll = React.useRef(0);

  const handleScroll = () => {
    if (window.scrollY > lastScroll.current + threshold) {
      onVisibilityChange(true);
      lastScroll.current = window.scrollY;
    }

    if (window.scrollY > 0 && window.scrollY < lastScroll.current - threshold) {
      onVisibilityChange(false);
      lastScroll.current = window.scrollY;
    }
  };

  React.useEffect(() => {
    if (window.innerWidth < Breakpoint.lg) {
      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Container isHidden={isHidden}>
      <SearchInput {...props} />
    </Container>
  );
};
