import React from "react";
import { HalController } from "api-web-client";
import styled from "styled-components";

import { NewIcon } from "components/NewIcon";
import { SearchScreen } from "resources/AudiotekaApi";
import Teaser from "modules/DesignSystem/components/Teaser";
import { Breakpoint } from "modules/DesignSystem/breakpoint";
import Container from "modules/DesignSystem/components/Container";

import { MobileGrid, Results, TeaserList } from "./SearchAndRecommendationResults.styled";

const TitleBox = styled(Container)`
  display: none;
  align-items: center;
  gap: 8px;
  margin: 24px auto 16px;

  :first-of-type {
    margin-top: 0;
  }

  @media (min-width: ${Breakpoint.lg}px) {
    display: flex;
  }
`;

const TitleBoxMobile = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 24px auto 18px;

  :first-of-type {
    margin-top: 10px;
  }
`;

const Title = styled.span`
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  color: #757575;
`;

const MobileGridWrapper = styled.div`
  max-width: 344px;
  margin: 0 auto;

  @media (min-width: ${Breakpoint.sm}px) {
    max-width: 524px;
  }

  @media (min-width: ${Breakpoint.lg}px) {
    display: none;
  }
`;

export const Recommendations = ({ recommendations }: { recommendations: SearchScreen | null }) => {
  if (!recommendations || recommendations._embedded["app:screen-section"].length === 0) {
    return null;
  }

  const popular =
    recommendations._embedded["app:screen-section"][0]?._embedded["app:product"]
      .slice(0, 20)
      .map((product) => HalController.fromObject<HALProduct>(product as unknown as HALObject)) ?? [];

  const recommended =
    recommendations._embedded["app:screen-section"][1]?._embedded["app:product"]
      .slice(0, 20)
      .map((product) => HalController.fromObject<HALProduct>(product as unknown as HALObject)) ?? [];

  const popularTitle = recommendations._embedded["app:screen-section"][0]?.title;
  const recommendedTitle = recommendations._embedded["app:screen-section"][1]?.title;

  return (
    <Results>
      {popularTitle && (
        <TitleBox>
          <NewIcon icon="popular" width={24} height={24} />
          <Title>{popularTitle}</Title>
        </TitleBox>
      )}
      <TeaserList elements={popular} />

      {recommendedTitle && (
        <TitleBox>
          <NewIcon icon="recommended" width={24} height={24} />
          <Title>{recommendedTitle}</Title>
        </TitleBox>
      )}
      <TeaserList elements={recommended} />

      <MobileGridWrapper>
        {popularTitle && (
          <TitleBoxMobile>
            <NewIcon icon="popular" width={24} height={24} />
            <Title>{popularTitle}</Title>
          </TitleBoxMobile>
        )}
        <MobileGrid>
          {recommendations._embedded["app:screen-section"][0]?._embedded["app:product"].slice(0, 6).map((product) => (
            <Teaser key={product.id} product={HalController.fromObject<HALProduct>(product as unknown as HALObject)} />
          ))}
        </MobileGrid>

        {recommendedTitle && (
          <TitleBoxMobile>
            <NewIcon icon="recommended" width={24} height={24} />
            <Title>{recommendedTitle}</Title>
          </TitleBoxMobile>
        )}
        <MobileGrid>
          {recommendations._embedded["app:screen-section"][1]?._embedded["app:product"].slice(0, 6).map((product) => (
            <Teaser key={product.id} product={HalController.fromObject<HALProduct>(product as unknown as HALObject)} />
          ))}
        </MobileGrid>
      </MobileGridWrapper>
    </Results>
  );
};
