import React from "react";
import styled from "styled-components";

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: calc(100dvh - var(--top-bar-height));
  z-index: -1;
`;

const Backdrop = styled.div`
  background: hsla(0, 0%, 0%, 0.7);
  position: fixed;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  touch-action: none;
`;

export const SearchResultsLayout = ({ children, onClose }: { children: React.ReactNode; onClose: () => void }) => {
  React.useEffect(() => {
    // Add the class to the body element when the component mounts
    document.body.classList.add("disable-scrolling");

    // Remove the class from the body element when the component unmounts
    return () => {
      document.body.classList.remove("disable-scrolling");
    };
  }, []);

  return (
    <Container>
      <Backdrop onClick={onClose} />
      {children}
    </Container>
  );
};
