import styled from "styled-components";

import { Breakpoint } from "modules/DesignSystem/breakpoint";

import { SearchInput } from "./SearchInput";

export const SearchInputDesktop = styled(SearchInput)`
  --input-height: 40px;
  display: none;

  @media (min-width: ${Breakpoint.lg}px) {
    display: flex;
    margin: 0 40px;
    max-width: 600px;
  }
`;
