import styled, { css } from "styled-components";

import { MEDIA_QUERY, Breakpoint } from "modules/DesignSystem/breakpoint";
import ButtonPrimitive from "modules/DesignSystem/components/ButtonPrimitive";
import BaseContainer from "modules/DesignSystem/components/Container";
import Logo from "modules/DesignSystem/components/Logo";
import Icon from "modules/DesignSystem/components/Icon";
import Link from "components/link";
import { topBarConfig } from "consts";

export const TopBarStyled = styled.div<{ searchVisible: boolean; isMobileInputHidden: boolean }>`
  color: #fff;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 100;

  --mobile-search-input-height: ${({ isMobileInputHidden }) => (isMobileInputHidden ? 0 : 64)};
  --top-bar-height: calc((${topBarConfig.mobileHeight} + var(--mobile-search-input-height)) * 1px);
  @media (min-width: ${Breakpoint.lg}px) {
    --top-bar-height: ${topBarConfig.desktopHeight}px;
  }

  --search-input-border-color: ${({ searchVisible }) => (searchVisible ? "#fff" : "rgba(255, 255, 255, 0.5)")};
  --search-button-color: ${({ searchVisible }) => (searchVisible ? "#000" : "#fff")};
  --search-button-bg-color: ${({ searchVisible }) => (searchVisible ? "#fff" : "transparent")};
  --item-visibility: ${({ searchVisible }) => (searchVisible ? "hidden" : "visible")};
  --item-display: ${({ searchVisible }) => (searchVisible ? "none" : "block")};
`;

export const TopBarBg = styled.div`
  background: #160829;
`;

export const CatalogBtn = styled.button<{ open?: boolean }>`
  visibility: var(--item-visibility);
  display: none;

  ${Icon} {
    margin-left: 15px;
    transform: rotateZ(0deg);
    transition: transform 0.2s ease;
  }

  ${({ open }) =>
    open
      ? css`
          ${Icon} {
            transform: rotateZ(-180deg);
          }
        `
      : ""}

  @media ${MEDIA_QUERY.md} {
    align-items: center;
    background: transparent;
    border: none;
    box-shadow: -1px 0 0 rgba(255, 255, 255, 0.2);
    color: inherit;
    cursor: pointer;
    display: inline-flex;
    font-size: 18px;
    padding: 8px 0 8px 15px;
  }
`;

export const CatalogBtnMobile = styled.button`
  display: var(--item-display);
  background: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  padding: 0;

  & > svg {
    display: flex;
  }

  @media ${MEDIA_QUERY.md} {
    display: none;
  }
`;

export const Container = styled(BaseContainer)`
  display: flex;
  position: relative;
  flex-direction: column;
  height: ${topBarConfig.mobileHeight}px;

  @media (min-width: ${Breakpoint.lg}px) {
    height: ${topBarConfig.desktopHeight}px;
    flex-direction: row;
  }

  @media ${MEDIA_QUERY.xxl} {
    overflow: visible;
  }
`;

export const FlexWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Column = styled.div`
  align-items: center;
  display: grid;
  flex-shrink: 0;
  gap: 15px;
  grid-template-columns: repeat(2, auto);
  height: 100%;
  justify-content: start;
`;

export const ColumnWithIcons = styled(Column)`
  position: relative;
  visibility: var(--item-visibility);
  justify-content: flex-end;
  gap: 0;
`;

export const CloseSearchButton = styled(ButtonPrimitive)<{ searchVisible: boolean }>`
  display: ${({ searchVisible }) => (searchVisible ? "block" : "none")};
  visibility: ${({ searchVisible }) => (searchVisible ? "visible" : "hidden")};
  position: absolute;
  right: 0;
  text-decoration: underline;
  font-size: 14px;
`;

export const LogoStyled = styled(Logo)`
  display: block;
  height: 20px;

  @media ${MEDIA_QUERY.md} {
    height: 24px;
  }
`;

export const LogoLink = styled(Link)`
  display: block;
  padding: 12px 0;
`;
