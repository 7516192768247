import React, { PropsWithChildren, PureComponent } from "react";
import { withRouter } from "next/router";
import { WithRouterProps } from "next/dist/client/with-router";
import gtm from "react-gtm-module";

import { WithUser, withUser } from "modules/user";
import { getPrices } from "modules/Product/utils";
import api from "api-web-client";
import { getProductType } from "utils/products";
import { useLaunchTestDomain } from "utils/useLaunchTestDomain";

import { CartModal } from "./cart-modal";
import { cartConfig } from "./cart.config";
import { cartContext } from "./cart.context";
import { CartContext } from "./cart.types";

type Props = PropsWithChildren & Partial<WithRouterProps & WithUser & { launchTestDomain: string }>;
type State = Omit<CartContext, "addToCart" | "removeFromCart">;

function withLaunchTestDomain(Component: React.ComponentClass) {
  return (props) => {
    const launchTestDomain = useLaunchTestDomain();

    return <Component {...props} launchTestDomain={launchTestDomain} />;
  };
}

@(withUser as any)
@(withRouter as any)
@(withLaunchTestDomain as any)
export class OldCartProvider extends PureComponent<Props, State> {
  /*
   * Constructor
   */
  constructor(props) {
    super(props);

    this.state = {
      recentItem: null,
      currency: cartConfig.defaultCurrency,
      isLoading: false,
      items: [],
      value: 0,
    };
  }

  /*
   * Lifecycle
   */
  public componentDidMount() {
    this._refreshCounter();

    api.getVisitor(this.props.router.locale).then((visitor) => this.setState({ currency: visitor.data.currency }));
  }

  public componentDidUpdate(prevProps) {
    if (prevProps.user.id !== this.props.user.id || prevProps.launchTestDomain !== this.props.launchTestDomain) {
      this._refreshCounter();
    }
  }

  /*
   * Getters
   */
  public get language(): string {
    return this.props.router.locale || this.props.router.defaultLocale!;
  }

  /*
   * Private methods
   */
  private _clearAddedItem = () => {
    this.setState({ recentItem: null });
  };

  private _refreshCounter = async () => {
    try {
      const response = await fetch(
        `${this.props.launchTestDomain}/${this.language}/user/profile.json?mode=cart&v=${Date.now()}`,
        { credentials: "include" }
      );
      const { cart } = await response.json();

      this.setState({ items: cart.products });

      return cart;
    } catch {
      this.setState({ items: [] });

      return null;
    }
  };

  /*
   * Public methods
   */
  public addToCart: CartContext["addToCart"] = async (product, licenses) => {
    const { user } = this.props;
    this.setState({ isLoading: true });

    const referenceIdWithoutCatalog = product.data.reference_id.replace(/[a-z]{2}_/, "");

    const request = await fetch(
      `${this.props.launchTestDomain}/${this.language}/cart/add/${referenceIdWithoutCatalog}`,
      { credentials: "include" }
    );

    if (request.ok) {
      const price = getPrices(product, licenses, user.isClubMember);

      const cart = await this._refreshCounter();

      gtm.dataLayer({
        dataLayer: {
          event: "add_to_cart",
          ecommerce: {
            currency: this.state.currency,
            value: (cart.products || []).map((item) => item.price).reduce((a, b) => a + b),
            items: [
              {
                coupon: cart.promocode,
                discount: price.base - price.final,
                index: 0,
                item_id: product.data.id,
                item_name: product.data.name,
                item_variant: getProductType(product),
                price: price.final,
                quantity: 1,
              },
            ],
          },
        },
      });

      this.setState({
        recentItem: { product, price: price.final * 100 },
        isLoading: false,
      });
    } else {
      this.setState({ isLoading: false });
    }
  };

  // eslint-disable-next-line class-methods-use-this
  public removeFromCart = () => {
    // Not implemented in old cart but required in context
  };

  /*
   * Render
   */
  public render() {
    return (
      <cartContext.Provider value={{ ...this.state, addToCart: this.addToCart, removeFromCart: this.removeFromCart }}>
        {this.state.recentItem && <CartModal cartItem={this.state.recentItem} onClose={this._clearAddedItem} />}
        {this.props.children}
      </cartContext.Provider>
    );
  }
}
