/* eslint-disable class-methods-use-this, no-plusplus */

import api, { HalController } from "api-web-client";

import { browserName, isServer } from "utils/runtime";
import { isId } from "utils/string";
import { EventManager } from "utils/event-manager";

import { AudiobookChapter, PlayerLoadOptions } from "./player.context";
import { secondsToTimeString, smartResume } from "./player.utils";
import { PlayerEventName, ShakaEventName, AudiobookPlayNext } from "./player.types";

interface EventListener {
  (event: Event | null): void;
}

interface PlayerAudiobook {
  authors: {
    id: string;
    name: string;
    slug: string;
  }[];
  cover: URL;
  id: string;
  isBasicPlanLimited: boolean;
  isFree: boolean;
  isSample: boolean;
  playNext: AudiobookPlayNext;
  progress: number;
  slug: string;
  title: string;
}

interface PlayerMediaDrm {
  chapters: [];
  contentId: string;
  id: string;
  manifestUrl: string;
}

interface PlayerMediaFile {
  duration: number;
  fileUrl: string;
  id: string;
  streamUrl: string;
}

interface PlayerMediaMp3 {
  chapters: [];
  files: PlayerMediaFile[];
  id: string;
}

interface PlayerMedia {
  drm: PlayerMediaDrm | null;
  mp3: PlayerMediaMp3 | null;
}

interface PlayerClass {
  on(eventName: PlayerEventName | ShakaEventName, listener: EventListener): void;
}

type LicenseType = "CAN_LISTEN" | "CAN_BUY" | "NOT_AVAILABLE" | "NOT_AVAILABLE_ON_THIS_DEVICE";

class Player implements PlayerClass {
  /*
   * Static methods
   */
  public static parseChapters(key: string, media: HalController, audiobook: HalController, isSample: boolean) {
    const sampleDurationInSeconds = audiobook.data.sample_duration_in_minutes * 60;
    let tmpTime = 0;

    const { toc } = media.data[key];

    return toc.map(({ title, duration_in_ms }): AudiobookChapter => {
      const startTime = tmpTime;
      const endTime = startTime + duration_in_ms / 1000;
      tmpTime = endTime;

      return {
        available: isSample ? startTime < sampleDurationInSeconds : true,
        endTime: Math.floor(endTime),
        timeString: secondsToTimeString(startTime),
        title,
        startTime: Math.floor(startTime),
      };
    });
  }

  /*
   * Private properties
   */
  private _eventManager = new EventManager();

  private _shaka = null;

  private _media: PlayerMedia = {
    drm: null,
    mp3: null,
  };

  private _mp3FileStartAt = 0;

  private _mp3Index = -1;

  private _audiobook: PlayerAudiobook | null = null;

  private _loading = false;

  private _buffering = false;

  /*
   * Audio properties
   */
  private _audio: HTMLAudioElement = isServer ? null : document.createElement("audio");

  private _currentTime = 0;

  private _duration = 0;

  /*
   * Private getters & setters
   */
  private set _isLoading(loading: boolean) {
    // Is currently loading vs should be loading
    const hasChanged = (this._loading || this._buffering) !== (loading || this._buffering);

    this._loading = loading;

    if (hasChanged) {
      this._dispatchEvent(new Event("loadingstatechange"));
    }
  }

  private set _isBuffering(buffering: boolean) {
    // Is currently loading vs should be loading
    const hasChanged = (this._loading || this._buffering) !== (this._loading || buffering);

    this._buffering = buffering;

    if (hasChanged) {
      this._dispatchEvent(new Event("loadingstatechange"));
    }
  }

  /*
   * Public getters & setters
   */
  public get audiobook() {
    return this._audiobook;
  }

  public get chapters() {
    return (this._media.drm || this._media.mp3).chapters;
  }

  public get currentTime() {
    return this._currentTime;
  }

  public set currentTime(time: number) {
    if (this._media.drm) {
      this._audio.currentTime = time;
    } else {
      let duration = 0;
      let previousDuration = 0;

      const mp3Index = this._media.mp3.files.findIndex((file) => {
        duration += file.duration;

        if (time < duration) {
          return true;
        }

        previousDuration = duration;
        return false;
      });

      if (mp3Index === -1) {
        // Seek out of range. End audiobook.
        this._audio.currentTime = this._audio.duration;
      } else if (mp3Index !== this._mp3Index) {
        this._loadMp3(mp3Index, time - previousDuration);
      } else {
        this._audio.currentTime = time - previousDuration;
      }
    }
  }

  public get defaultPlaybackRate() {
    return this._audio.defaultPlaybackRate;
  }

  public set defaultPlaybackRate(rate: number) {
    this._audio.defaultPlaybackRate = rate;
  }

  public get duration() {
    if (!this._media.drm) {
      return this._duration;
    }

    return this._audio.duration;
  }

  public get mediaId(): string {
    if (this._media.drm) {
      return this._media.drm.id;
    }

    return this._media.mp3.id;
  }

  public get muted() {
    return this._audio.muted;
  }

  public set muted(muted: boolean) {
    this._audio.muted = muted;
  }

  public get paused() {
    return this._audio.paused;
  }

  public get playbackRate() {
    return this._audio.playbackRate;
  }

  public set playbackRate(rate: number) {
    this._audio.playbackRate = rate;
  }

  public get volume() {
    return this._audio.volume;
  }

  public set volume(volume: number) {
    this._audio.volume = volume;
  }

  public get isLoading() {
    return this._loading || this._buffering;
  }

  /*
   * Constructor
   */
  constructor() {
    if (!isServer) {
      this._audio.addEventListener("ended", this._handleEnded);
      this._audio.addEventListener("loadeddata", this._forwardEvent);
      this._audio.addEventListener("loadedmetadata", this._forwardEvent);
      this._audio.addEventListener("play", this._handlePlay);
      this._audio.addEventListener("pause", this._forwardEvent);
      this._audio.addEventListener("ratechange", this._forwardEvent);
      this._audio.addEventListener("timeupdate", this._handleTimeUpdate);
      this._audio.addEventListener("volumechange", this._forwardEvent);
      this._audio.addEventListener("canplay", this._forwardEvent);
    }
  }

  /*
   * Private methods
   */
  private _forwardEvent = (event: Event) => {
    this._dispatchEvent(event);
  };

  private _handleEnded = (event: Event) => {
    if (this._media.drm) {
      this._dispatchEvent(event);
    } else {
      const nextMp3 = this._mp3Index + 1;

      if (this._media.mp3.files[nextMp3]) {
        this._loadMp3(nextMp3);
      } else {
        this._dispatchEvent(event);
      }
    }
  };

  private _handlePlay = (event: Event) => {
    this._dispatchEvent(event);
  };

  private _handleTimeUpdate = (event: Event) => {
    if (this._media.drm) {
      this._currentTime = this._audio.currentTime;
    } else {
      this._currentTime = this._mp3FileStartAt + this._audio.currentTime;
    }

    this._dispatchEvent(event);
  };

  private _dispatchEvent(event: Event) {
    this._eventManager.dispatch(event);
  }

  private async _getLicenses(audiobook: HalController<HALAudiobook>): Promise<LicenseType[]> {
    try {
      const licenses = await audiobook.follow("app:license-channels");

      return Object.values(licenses.data)
        .filter((value) => typeof value === "object" && "state" in value)
        .map(({ state }: any) => state);
    } catch {
      return [];
    }
  }

  private _isSample(licenses: LicenseType[]) {
    return !licenses.includes("CAN_LISTEN");
  }

  private _isBasicPlanLimited(licenses: LicenseType[]) {
    return this._isSample(licenses) && licenses.includes("NOT_AVAILABLE_ON_THIS_DEVICE");
  }

  private async _getPlayNext(audiobook: HalController, isSample: boolean): Promise<AudiobookPlayNext> {
    try {
      if (isSample) {
        throw new Error("Don't get playNext for sample");
      }

      const recommendedAfter = await audiobook.follow("app:recommended-after-list");

      return {
        autoPlay: recommendedAfter.data.autoplay,
        list: (recommendedAfter.embedded["app:product"] as HalController[]).map((product) => {
          const cover = new URL(product.data.image_url);
          cover.searchParams.set("auto", "format");
          cover.searchParams.set("w", "280");

          return {
            author: product.data.description,
            cover,
            id: product.data.id,
            slug: product.data.slug,
            title: product.data.name,
          };
        }),
      };
    } catch {
      return {
        autoPlay: false,
        list: [],
      };
    }
  }

  private async _getProgress(audiobook: HalController): Promise<number> {
    try {
      const progress = await audiobook.follow("app:playback-progress");
      const progressInSeconds = Math.floor(progress.data.position / 1000);

      return smartResume(audiobook.data.id, progressInSeconds);
    } catch {
      return 0;
    }
  }

  private async _loadMedia(audiobook: HalController, isSample: boolean) {
    const media = await audiobook.follow("app:audiobook-media", { samples: String(isSample) });

    const protocol = browserName === "Safari" ? "hls" : "dash";

    this._media = {
      drm: media.data[protocol]
        ? {
            chapters: Player.parseChapters(protocol, media, audiobook, isSample),
            contentId: media.data[protocol].key_id,
            id: media.data[protocol].id,
            manifestUrl: media.data[protocol][isSample ? "sample_url" : "url"],
          }
        : null,
      mp3: media.data.audio_files
        ? {
            chapters: Player.parseChapters("audio_files", media, audiobook, isSample),
            files: media.data.audio_files.files.map(
              (file): PlayerMediaFile => ({
                duration: file.duration_in_ms / 1000,
                fileUrl: file.file_location,
                id: file.id,
                streamUrl: file.stream_location,
              })
            ),
            id: media.data.audio_files.id,
          }
        : null,
    };
  }

  private async _loadMp3(index: number, currentTime: number = 0) {
    if (!this._media.mp3.files[index]) {
      return;
    }

    this._mp3Index = index;

    let startAt = 0;

    if (index > 0) {
      for (let i = 0; i < index; i++) {
        startAt += this._media.mp3.files[i].duration;
      }
    }

    this._mp3FileStartAt = startAt;

    const data = await api.get(this._media.mp3.files[index].streamUrl);

    this._audio.src = data.url;
    this._audio.currentTime = currentTime;
  }

  private async _setupDrm() {
    if (!this._shaka.ready) {
      this._shaka.initialize(this._audio);

      this._shaka.on("buffering", (event: any) => {
        this._isBuffering = event.buffering;
      });

      this._shaka.on("error", this._forwardEvent);
    }

    this._shaka.setContentId(this._media.drm.contentId);
  }

  private _setupMp3() {
    if (this._shaka) {
      this._shaka.destroy();
    }

    this._duration = this._media.mp3.files.map((file) => file.duration).reduce((a, b) => a + b);
  }

  /*
   * Public methods
   */

  public on = this._eventManager.on;

  public off = this._eventManager.off;

  public load = async (audiobook: HalController, options: PlayerLoadOptions = {}) => {
    if (!this._shaka) {
      const ShakaPlayer = (await import("./shaka-player")).default;
      this._shaka = new ShakaPlayer();
    }

    this._isLoading = true;

    this.unload();

    const [licenses, progress] = await Promise.all([this._getLicenses(audiobook), this._getProgress(audiobook)]);

    const isSample = this._isSample(licenses);
    const isBasicPlanLimited = this._isBasicPlanLimited(licenses);

    const playNext = await this._getPlayNext(audiobook, isSample);

    const cover = new URL(audiobook.data.image_url);
    cover.searchParams.set("auto", "format");
    cover.searchParams.set("w", "360");

    this._audiobook = {
      authors: (audiobook.embedded["app:author"] as HalController[]).map((author) => ({
        id: author.data.id,
        name: author.data.name,
        slug: author.data.slug,
      })),
      cover,
      id: audiobook.data.id,
      isBasicPlanLimited,
      isFree: audiobook.data.is_free,
      isSample,
      playNext,
      progress,
      slug: audiobook.data.slug,
      title: audiobook.data.name,
    };

    await this._loadMedia(audiobook, isSample);

    if (this._media.drm) {
      await this._setupDrm();
      this._shaka.load(this._media.drm.manifestUrl, this._audiobook.progress);
    } else {
      await this._setupMp3();
      this.currentTime = this._audiobook.progress; // Loading mp3 file on currentTime update
    }

    this._isLoading = false;
    this._audio.autoplay = options.autoPlay || false;
  };

  public loadBySlugOrId = async (id: string, options: PlayerLoadOptions = {}) => {
    const [, locale] = window.location.pathname.match(/^\/([a-z]{2})(\/?|$)/) || [];
    const visitor = await api.getVisitor(locale);
    const audiobook = await visitor.follow("app:audiobook", { id: isId(id) ? id : `slug/${id}` });

    return this.load(audiobook, options);
  };

  public unload = () => {
    this._audio.pause();
    this._audio.currentTime = 0;

    this._audiobook = null;
    this._media = {
      drm: null,
      mp3: null,
    };
    this._isLoading = false;
    this._shaka.destroy();

    this._mp3Index = -1;

    this._dispatchEvent(new Event("unloaded"));
  };

  public pause = () => {
    this._audio.pause();
  };

  public play = () => {
    this._audio.play();
  };
}

const player = new Player();

export default player;
