import React from "react";
import { useRouter } from "next/router";

import { getSearchScreen, SearchScreen } from "resources/AudiotekaApi";
import { getLocationInfo } from "utils/getLocationInfo";

import { Recommendations } from "./Recommendations";
import { SearchResults } from "./SearchResults";
import { SearchResultsLayout } from "./SearchResultsLayout";

type Props = {
  searchResults: HALProduct[];
  searchTerm: string;
  visible: boolean;
  onClose: () => void;
};

export const SearchAndRecommendationResults = ({ searchResults, searchTerm, visible, onClose }: Props) => {
  const router = useRouter();
  const [recommendations, setRecommendations] = React.useState<SearchScreen | null>(null);

  React.useEffect(() => {
    async function loadRecommendations() {
      try {
        const { catalogId } = getLocationInfo(router.locale);
        const response = await getSearchScreen(catalogId);

        setRecommendations(response);
      } catch {
        setRecommendations(null);
      }
    }

    loadRecommendations();
  }, []);

  if (!visible) return null;

  return (
    <SearchResultsLayout onClose={onClose}>
      {searchResults.length > 0 && <SearchResults results={searchResults} />}
      {searchResults.length === 0 && !searchTerm && <Recommendations recommendations={recommendations} />}
    </SearchResultsLayout>
  );
};
