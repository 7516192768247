import React from "react";
import styled, { css } from "styled-components";

import Icon from "modules/DesignSystem/components/Icon";

import { usePrice } from "./usePrice";

const PriceIcon = styled(Icon)<{ colorless?: boolean }>`
  color: ${({ colorless }) => (colorless ? "inherit" : "#989898")};
`;

const Price = styled.p<{ base?: string; colorless?: boolean }>`
  align-items: flex-end;
  color: ${({ base, colorless }) => (base ? "#33adff" : colorless ? "inherit" : "#380089")};
  column-gap: 8px;
  display: flex;
  font-size: 16px;
  font-weight: 700;
  grid-column: 2;
  line-height: 18px;
  margin: 0;

  ${({ base }) =>
    base
      ? css`
          &::after {
            color: #989898;
            content: "${base}";
            font-size: 14px;
            font-weight: 400;
            text-decoration: line-through;
          }
        `
      : ""}
`;

interface Props {
  colorless?: boolean;
  noPrice?: boolean;
  productData: HALProductData;
}

export const FooterRight = ({ colorless, productData, noPrice }: Props) => {
  const { isClubMember, isFreeInClub, cycle, shelf, basePrice, isClubPrice, finalPrice } = usePrice(
    productData,
    noPrice
  );

  if (shelf) {
    return <PriceIcon colorless={colorless} name="shelf" />;
  }

  if (cycle) {
    return <PriceIcon colorless={colorless} name="cycle" />;
  }

  if (noPrice) return null;

  if (finalPrice && !(isFreeInClub && isClubMember)) {
    return (
      <Price base={!isClubPrice && basePrice !== finalPrice ? basePrice : undefined} colorless={colorless}>
        {finalPrice}
      </Price>
    );
  }

  return null;
};
