import React from "react";
import styled from "styled-components";

import { MEDIA_QUERY } from "modules/DesignSystem/breakpoint";
import { useTranslation } from "common/i18n";

import { usePrice } from "./usePrice";

const Wrapper = styled.div`
  flex: 1;
`;

const LowestPrice = styled.div<{ colorless?: boolean }>`
  color: ${({ colorless }) => (colorless ? "inherit" : "#383838")};
  font-size: 10px;
  font-weight: 400;
  margin-top: 10px;

  @media screen and ${MEDIA_QUERY.lg} {
    font-size: 12px;
  }
`;

interface Props {
  colorless?: boolean;
  noPrice?: boolean;
  productData: HALProductData;
}

const FooterBottomContent = ({ colorless, productData, noPrice }: Props) => {
  const { isClubMember, isFreeInClub, cycle, shelf, basePrice, isClubPrice, finalPrice, lowestPrice } = usePrice(
    productData,
    noPrice
  );

  const { t } = useTranslation();

  if (noPrice) return null;

  if (shelf || cycle) return null;

  if (finalPrice && !(isFreeInClub && isClubMember)) {
    return !isClubPrice && finalPrice !== basePrice && lowestPrice ? (
      <LowestPrice colorless={colorless}>
        {t("product.lowest_price", { price: lowestPrice.replace(/ /g, "\xa0") })}
      </LowestPrice>
    ) : null;
  }

  return null;
};

export const FooterBottom = (props: Props) => (
  <Wrapper>
    <FooterBottomContent {...props} />
  </Wrapper>
);
