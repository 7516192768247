import React, { useRef, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { useUser } from "modules/user";
import IconWithBadge from "modules/DesignSystem/components/IconWithBadge";
import { useCart } from "modules/cart/cart.hooks";
import { MiniCart } from "modules/cart/mini-cart";
import { useLaunchTestDomain } from "utils/useLaunchTestDomain";

import { MenuLink, NewIcon } from "./IconButtons.styled";

const Root = styled.div`
  position: relative;
`;

export function CartButton() {
  const visibleRef = useRef<number | NodeJS.Timeout>();

  const cart = useCart();
  const { isAlphaTester } = useUser();
  const { t } = useTranslation();
  const launchTestDomain = useLaunchTestDomain();

  const [isVisible, setVisible] = useState(false);

  const mouseEnter = () => {
    clearTimeout(visibleRef.current);
    setVisible(true);
  };

  const mouseLeave = () => {
    visibleRef.current = setTimeout(() => {
      setVisible(false);
    }, 250);
  };

  if (isAlphaTester) {
    return (
      <Root>
        <MenuLink route="cart" title={t("navigation.cart")} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
          <IconWithBadge value={cart.items.length}>
            <NewIcon icon="shopping-cart" width="24" height="24" />
          </IconWithBadge>
        </MenuLink>
        <MiniCart open={isVisible} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} />
      </Root>
    );
  }

  return (
    <MenuLink href={`${launchTestDomain}/[lang]/cart`} title={t("navigation.cart")}>
      <IconWithBadge value={cart.items.length}>
        <NewIcon icon="shopping-cart" width="24" height="24" />
      </IconWithBadge>
    </MenuLink>
  );
}
