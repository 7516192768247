import { HalController } from "api-web-client";

import type { UserSubscriber } from "./user.types";

type Unsubscribe = () => void;

const subscriberSet = new Set<UserSubscriber>();

export function subscribeUser(subscriber: UserSubscriber): Unsubscribe {
  subscriberSet.add(subscriber);

  return () => subscriberSet.delete(subscriber);
}

export function setUser(user: HalController | null) {
  subscriberSet.forEach((subscriber) => subscriber(user));
}
