import React, { FC, InputHTMLAttributes } from "react";

import { Grip, Input, Root } from "./switch.styled";

interface Props extends Omit<InputHTMLAttributes<HTMLInputElement>, "type"> {
  type?: "checkbox" | "radio";
}

export const Switch: FC<Props> = ({ className, checked, ...inputProps }) => {
  return (
    <Root className={className} checked={checked}>
      <Input {...inputProps} checked={checked} />
      <Grip />
    </Root>
  );
};

Switch.defaultProps = {
  type: "checkbox",
};
