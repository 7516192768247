import styled from "styled-components";
import Link from "components/link";
import Skeleton from "modules/DesignSystem/components/Skeleton";

import { Button } from "./ActionButton/ActionButton.styled";

export const Author = styled.p<{ colorless?: boolean }>`
  color: ${({ colorless }) => (colorless ? "inherit" : "#8d889e")};
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin: 0 0 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Cover = styled.img`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transform-origin: center;
  transition: transform 1s ease-in-out;
  width: 100%;
  z-index: 2;
`;

export const CoverContainer = styled(Skeleton)`
  background: rgba(0, 0, 0, 0.1);
  margin-bottom: 8px;
  padding-top: 100%;
  width: 100%;
`;

export const Footer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 4px;
`;

export const FooterTop = styled.div`
  align-items: flex-end;
  display: flex;
`;

export const FooterSpacer = styled.div`
  flex: 1;
`;

export const MainLink = styled(Link)`
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
`;

export const Progress = styled.div<{ progress: number }>`
  background: #4dffc5;
  bottom: 0;
  height: 5px;
  left: 0;
  position: absolute;
  width: ${({ progress }) => progress}%;
  z-index: 3;
`;

export const Rating = styled.div<{ colorless?: boolean }>`
  align-items: center;
  color: ${({ colorless }) => (colorless ? "inherit" : "#989898")};
  column-gap: 6px;
  display: flex;
  grid-column: 1;
  grid-row: 4;
  font-size: 14px;
  font-weight: 400;
`;

export const Root = styled.div<{ colorless?: boolean; dark?: boolean }>`
  align-self: stretch;
  box-sizing: border-box;
  color: ${({ colorless, dark }) => (colorless ? "inherit" : dark ? "#fff" : "#160829")};
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;

  &:hover {
    ${Cover} {
      transform: scale(1.05);
    }

    ${Button} {
      opacity: 1;
    }
  }
`;

export const Title = styled.p`
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  margin: 0 0 4px;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
