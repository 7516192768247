import React, { useState } from "react";

import { useTranslation } from "common/i18n";
import { NewIcon } from "components/NewIcon";
import ButtonPrimitive from "modules/DesignSystem/components/ButtonPrimitive";
import { useUser } from "modules/user";

import { userMenuItems } from "../consts";
import { Backdrop, Description, Icon, Link, Button, ItemContent, List, Wrapper } from "./Dropdown.styled";

const Dropdown = () => {
  const [open, setOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  const { email: userEmail, signOut } = useUser();

  return (
    <Wrapper>
      {!open ? (
        <ButtonPrimitive onClick={() => setOpen(true)}>
          <NewIcon icon="user" width="24" height="24" />
        </ButtonPrimitive>
      ) : (
        <>
          <Backdrop onClick={() => setOpen(false)} />
          <ButtonPrimitive onClick={() => setOpen(false)}>
            <NewIcon icon="close" width="24" height="24" />
          </ButtonPrimitive>
          <List onClick={() => setOpen(false)}>
            {userMenuItems.map(({ title, name, icon, ...linkProps }) => (
              <Link key={name} title={t(title)} {...linkProps}>
                <Icon icon={icon} width="28" height="28" />
                <ItemContent>
                  <span>{t(name)}</span>
                </ItemContent>
              </Link>
            ))}
            <Button title={t("navigation.logout")} onClick={signOut}>
              <Icon icon="logout" width="28" height="28" />
              <ItemContent>
                <span>{t("navigation.logout")}</span>
                <Description>{userEmail}</Description>
              </ItemContent>
            </Button>
          </List>
        </>
      )}
    </Wrapper>
  );
};

export default Dropdown;
