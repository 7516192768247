import styled, { css } from "styled-components";

import { Link as BaseLink } from "components/link";
import { lightStrongAdditional } from "modules/DesignSystem/colors";
import ButtonPrimitive from "modules/DesignSystem/components/ButtonPrimitive";
import { NewIcon } from "components/NewIcon";

export const Wrapper = styled.div`
  font-size: 0;
  position: relative;
`;

export const Backdrop = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
`;

export const List = styled.div`
  background-color: #fff;
  border-radius: 4px;
  font-size: 1rem;
  position: absolute;
  right: 0;
  top: 100%;
  padding: 0;
`;

const itemStyles = css`
  background-color: #fff;
  color: #160829;
  cursor: pointer;
  display: flex;
  padding: 10px 18px;
  text-align: left;
  text-decoration: none;
  width: 100%;
  &:hover {
    background-color: rgba(91, 189, 255, 0.2);
  }

  &:active {
    background-color: rgba(51, 173, 255, 0.4);
  }

  &:first-child {
    border-radius: 4px 4px 0 0;
  }

  &:last-child {
    border-radius: 0 0 4px 4px;
  }
`;

export const Link = styled(BaseLink)`
  ${itemStyles}
`;

export const Button = styled(ButtonPrimitive)`
  ${itemStyles}
`;

export const ItemContent = styled.div`
  padding-top: 4px;

  span {
    display: block;
    white-space: nowrap;
  }
`;

export const Description = styled.span`
  font-size: 14px;
  color: #8d889e;
`;

export const Icon = styled(NewIcon)`
  color: ${lightStrongAdditional};
  margin-right: 14px;
`;
