import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRouter } from "next/router";
import * as Sentry from "@sentry/nextjs";

import Button from "modules/DesignSystem/components/Button/Button";
import { useFavourites } from "modules/Favourites/FavouritesProvider";
import { useUser } from "modules/user";
import { getProductRelations } from "resources/AudiotekaApi";

import { cartConfig } from "../../cart.config";
import { useCart } from "../../cart.hooks";
import { joinPriceWithCurrency } from "../../cart.utils";
import { CartProduct } from "./cart-product";
import { Limit, List, Summary, Value, ValueName } from "./non-empty.styled";

export function NonEmpty() {
  const cart = useCart();
  const { locale } = useRouter();
  const { t } = useTranslation();
  const { count } = useFavourites();
  const { isLoggedIn } = useUser();

  const [favouriteIds, setFavouriteIds] = useState<string[]>([]);

  useEffect(() => {
    async function fetchProductRelations() {
      try {
        const ids = cart.items.map((item) => item.details.id);
        const response = await getProductRelations(ids);

        setFavouriteIds(response.relations.filter((rel) => rel.favorite).map((rel) => rel.product_id));
      } catch (e) {
        Sentry.captureException(`CartProduct | Failed to get product relations | Error: ${e}`);
      }
    }

    if (isLoggedIn) {
      fetchProductRelations();
    }
  }, [count, cart.items.length, isLoggedIn]);

  return (
    <>
      {cart.items.length >= cartConfig.itemsLimit && <Limit>{t("cart.limit_reached.title")}</Limit>}
      <List>
        {cart.items.map((product) => (
          <CartProduct
            product={product}
            isFavourite={favouriteIds.includes(product.details.id)}
            key={product.details.id}
          />
        ))}
      </List>
      <Summary>
        <ValueName>{t("cart.value")}</ValueName>
        <Value>{joinPriceWithCurrency(cart.value / 100, cart.currency, locale)}</Value>
        <Button route="cart">{t("cart.go_to_cart")}</Button>
      </Summary>
    </>
  );
}
