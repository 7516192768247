import React from "react";
import styled from "styled-components";

import { NewIcon } from "components/NewIcon";
import { Link as BaseLink } from "components/link";
import { useTranslation } from "common/i18n";

import type { MenuMobileItem } from "../MenuMobile";
import MenuMobile, { titleStyles } from "../MenuMobile";

const Link = styled(BaseLink)<{ accent: boolean }>`
  align-items: center;
  color: ${({ accent }) => (accent ? "#33adff" : "#fff")};
  cursor: pointer;
  display: grid;
  font-weight: ${({ accent }) => (accent ? 700 : 400)};
  gap: 16px;
  grid-template-columns: auto 1fr;
  text-align: left;
  text-decoration: none;

  svg {
    color: #33adff;
  }
`;

const Title = styled(BaseLink)`
  ${titleStyles}
`;

type Props = {
  open: boolean;
  items: MenuMobileItem[];
  onClose: () => void;
};

export const CatalogMenu = ({ items, open, onClose }: Props) => {
  const { t } = useTranslation();

  return (
    <MenuMobile open={open}>
      <MenuMobile.Header onClose={onClose}>
        <Title route="catalog" onClick={onClose}>
          <NewIcon icon="menu" width="24" height="24" />
          <div>{t("navigation.catalog")}</div>
        </Title>
      </MenuMobile.Header>
      <MenuMobile.Content size={items.length} open={open}>
        {items.map((item) => (
          <Link
            key={item.name}
            route={item.route}
            params={item.params}
            accent={item.accent}
            title={item.name}
            onClick={onClose}
          >
            {item.name}
          </Link>
        ))}
      </MenuMobile.Content>
    </MenuMobile>
  );
};
