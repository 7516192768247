import { ComponentProps, ComponentType, createElement } from "react";

import useToaster from "./hooks";

export type WithToaster<P = {}> = P & {
  toaster: IToasterContext;
};

const withToaster =
  <C extends ComponentType<ComponentProps<C> & WithToaster>>(component: C) =>
  (props): JSX.Element => {
    const toaster = useToaster();

    return createElement(component, { ...props, toaster });
  };

export default withToaster;
