import styled, { css } from "styled-components";

export const Root = styled.div<{ open?: boolean }>`
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px #0001;
  color: #000;
  position: absolute;
  right: calc(100% - 10px);
  top: calc(100% + 10px);
  transition: opacity 0.25s ease, visibility 0.25s ease;
  width: 350px;

  ${({ open }) => css`
    opacity: ${open ? 1 : 0};
    visibility: ${open ? "visible" : "hidden"};
  `}
`;

export const Title = styled.p`
  font-size: 1.375rem;
  font-weight: 700;
  margin: 24px 0 12px;
  text-align: center;
`;
