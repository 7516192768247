import React, { useState } from "react";
import { useRouter } from "next/router";
import Image from "next/image";

import { useTranslation } from "common/i18n";
import IconList from "modules/DesignSystem/components/IconList";
import { NewIcon } from "components/NewIcon";

import { MenuMobileItem } from "../MenuMobile";
import {
  AppLink,
  AppLinkList,
  Bottom,
  BottomContainer,
  Column,
  Columns,
  IconLink,
  Link,
  LinkBtn,
  LinkList,
  Title,
  Wrapper,
} from "./Footer.styled";
import { config } from "./config";
import { Catalog } from "./Catalog";

const Footer = ({ catalogItems }: { catalogItems: MenuMobileItem[] }) => {
  const [visibleColumns, setVisibleColumns] = useState([]);
  const { t } = useTranslation("common");

  const { locale } = useRouter();
  const footerConfig = config[locale];

  const toggleColumnVisibility = (column: number) => {
    setVisibleColumns((columns) => {
      const index = columns.indexOf(column);

      if (index > -1) {
        columns.splice(index, 1);
      } else {
        columns.push(column);
      }

      return [...columns];
    });
  };

  return (
    <Wrapper>
      <Catalog catalogItems={catalogItems} />
      <Columns extended>
        {footerConfig.columns.map((column, colIndex) => (
          <Column key={column.title}>
            <Title
              expanded={column.expandable ? visibleColumns.includes(colIndex) : undefined}
              onClick={column.expandable ? () => toggleColumnVisibility(colIndex) : undefined}
            >
              {t(column.title)}
            </Title>
            {column.apps && (
              <AppLinkList>
                <AppLink href={column.apps.android}>
                  <Image
                    style={{ width: "100%", height: "auto" }}
                    src="/gp-download.png"
                    alt="Get it on Google Play"
                    width="200"
                    height="60"
                  />
                </AppLink>
                <AppLink href={column.apps.ios}>
                  <Image
                    style={{ width: "100%", height: "auto" }}
                    src="/as-download.png"
                    alt="Download on the App Store"
                    width="200"
                    height="67"
                  />
                </AppLink>
              </AppLinkList>
            )}
            {column.links && (
              <LinkList expanded={column.expandable ? visibleColumns.includes(colIndex) : undefined}>
                {column.links.map(({ text, ...linkProps }) =>
                  linkProps.route || linkProps.href ? (
                    <Link key={text} {...linkProps}>
                      {t(text)}
                    </Link>
                  ) : (
                    <LinkBtn key={text} {...linkProps}>
                      {t(text)}
                    </LinkBtn>
                  )
                )}
              </LinkList>
            )}
          </Column>
        ))}
      </Columns>
      <Bottom>
        <BottomContainer>
          <IconList>
            {footerConfig.social.map((social) => (
              <IconLink key={social.icon} href={social.href}>
                <NewIcon icon={social.icon} width="24" height="24" />
              </IconLink>
            ))}
          </IconList>
          <p>{footerConfig.copy}</p>
        </BottomContainer>
      </Bottom>
    </Wrapper>
  );
};

export default Footer;
