import React, { PropsWithChildren } from "react";

import { ListStyled } from "./styled";

const IconList: React.FC<PropsWithChildren> = ({ children }) => (
  <ListStyled>
    {React.Children.map(
      children,
      (child: React.ReactElement<{ className: string }>) => child && <li className={child.props.className}>{child}</li>
    )}
  </ListStyled>
);

export default IconList;
