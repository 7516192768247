import styled from "styled-components";

import Link from "components/link";
import { NewIcon as BaseNewIcon } from "components/NewIcon";
import { lightStrongAdditional } from "modules/DesignSystem/colors";
import { MEDIA_QUERY } from "modules/DesignSystem/breakpoint";

export const IconsStyled = styled.div`
  color: ${lightStrongAdditional};

  ul {
    margin: 0;
  }

  > ul > li {
    display: none;

    &:hover {
      color: #fff;
    }
  }

  .show-on-mobile,
  .show-on-mobile-only {
    display: block;
  }

  @media ${MEDIA_QUERY.md} {
    > ul > li {
      display: block;
    }

    .show-on-mobile-only {
      display: none;
    }
  }
`;

export const LoginBtn = styled(Link)`
  color: #fff;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;

  @media ${MEDIA_QUERY.md} {
    background: #21004f;
    border-radius: 8px;
    color: #fff;
    padding: 10px;
    white-space: nowrap;

    &:hover {
      background: #3e008b;
    }

    svg {
      display: block;
      margin-right: 5px;
    }
  }
`;

export const MenuLink = styled(Link)`
  color: inherit;
`;

export const NewIcon = styled(BaseNewIcon)`
  display: flex;
`;
