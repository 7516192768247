import { createContext } from "react";

import { UserContext } from "./user.types";

export const userContext = createContext<UserContext>({
  email: "",
  hal: null,
  hasMarketingConsent: false,
  id: "",
  isAlphaTester: false,
  isClubMember: false,
  isLoading: true,
  isLoggedIn: false,
  signIn: async () => false,
  signOut: async () => undefined,
  signUp: async () => null,
});
