const { version: buildVersion } = require("../../../package.json");

// On prod there is CDN_HOST defined and needed buildVersion for S3.
const host = process.env.CDN_HOST ? `${process.env.CDN_HOST}/${buildVersion}/public` : "";

const serverLocalePath = "public/static/locales";
const browserLocalePath = `${host}/static/locales`;
const localeStructure = "{{lng}}/{{ns}}";
const localeExtension = "json";

const isBrowser = typeof window !== "undefined";

module.exports = {
  ns: ["common"],
  defaultNS: "common",
  i18n: {
    defaultLocale: "pl",
    locales: ["pl"],
    // locales: ["pl", "de", "cz"],
    localeDetection: false,
  },
  fallbackLng: "pl",
  localePath: serverLocalePath,
  localeStructure,
  backend: {
    projectId: process.env.BUILD_ID,
    version: "production",
    loadPath: `${isBrowser ? browserLocalePath : serverLocalePath}/${localeStructure}.${localeExtension}`,
  },
  detection: {
    order: ["path"],
  },
};
