export const config = {
  columns: [
    {
      title: "footer.customer_service",
      expandable: true,
      links: [
        { href: "https://audioteka.com/cz/buyers-guide", text: "footer.how_to_buy" },
        { href: "https://audioteka.com/cz/terms-of-use", text: "footer.terms_and_conditions" },
        { href: "https://audioteka.com/cz/privacy-policy", text: "footer.privacy_policy" },
        { route: "helpCenter", text: "footer.help_center_contact" },
      ],
    },
    {
      title: "footer.informations",
      expandable: true,
      links: [
        { href: "https://audioteka.com/cz/static/pripravujeme", text: "footer.upcoming_releases" },
        { href: "https://welcome.audioteka.com/", text: "footer.choose_language" },
      ],
    },
    {
      title: "footer.applications",
      apps: {
        android: "https://web.audioteka.com/cz/l/android",
        ios: "https://web.audioteka.com/cz/l/ios",
      },
      links: [{ href: "https://audioteka.com/cz/applications", text: "footer.all_apps" }],
    },
  ],
  social: [
    {
      href: "https://www.facebook.com/audioteka.cz",
      icon: "facebook",
    },
    {
      href: "https://www.youtube.com/channel/UC-YXUO6iZtf5fCFZOtEDJrQ",
      icon: "youtube",
    },
    {
      href: "https://www.instagram.com/audioteka_czsk/",
      icon: "instagram",
    },
    {
      href: "https://twitter.com/audioteka_cz",
      icon: "twitter",
    },
  ],
  copy: `Copyright © 2008-${new Date().getFullYear()} Audioteka Group Sp. z o.o.`,
};
