import styled from "styled-components";

import Button from "modules/DesignSystem/components/Button";

export const Limit = styled.p`
  background: #eeebf2;
  border-radius: 4px;
  font-size: 12px;
  margin: 15px;
  padding: 16px;
`;

export const List = styled.ul`
  max-height: 300px;
  overflow: auto;
  padding: 0 15px;
`;

export const Summary = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(2, auto);
  padding: 15px;

  > ${Button} {
    grid-column: 1/3;
  }
`;

export const Value = styled.p`
  color: #380089;
  font-weight: 700;
  margin: 0;
  text-align: right;
`;

export const ValueName = styled.p`
  margin: 0;
`;
