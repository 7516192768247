import React from "react";
import styled from "styled-components";

import BrandLogo from "./logo.svg";

const LogoStyled = styled.div`
  display: block;
  width: 100%;
  height: 100%;

  & svg {
    display: block;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    pointer-events: none;
    fill: currentColor;
  }
`;

const Logo = ({ className }: { className?: string }) => {
  return (
    <LogoStyled className={className} aria-label="Audioteka">
      <BrandLogo />
    </LogoStyled>
  );
};

export default Logo;
