import React from "react";

import { NewIcon } from "components/NewIcon";
import { usePlayer } from "modules/player";
import { Button } from "./ActionButton.styled";

type Props = {
  slug: string;
  isPlayable: boolean;
  action:
    | "play"
    | {
        hoverIcon: "heart-border";
        icon: "heart";
        onClick(): void;
      };
};

const PlayButton = ({ slug }: { slug: string }) => {
  const player = usePlayer();

  return (
    <Button onClick={() => player.load(slug, { autoPlay: true })}>
      <NewIcon icon="play" width="20" height="20" />
    </Button>
  );
};

export const ActionButton = ({ slug, isPlayable, action }: Props) => {
  if (action === "play") {
    if (!isPlayable) {
      return null;
    }

    return <PlayButton slug={slug} />;
  }

  return (
    <Button onClick={action.onClick}>
      <NewIcon icon={action.icon} width="20" height="20" />
      {action.hoverIcon && <NewIcon icon={action.hoverIcon} width="20" height="20" />}
    </Button>
  );
};
