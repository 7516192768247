import React from "react";

import IconWithBadge from "modules/DesignSystem/components/IconWithBadge";
import { useFavourites } from "modules/Favourites/FavouritesProvider";
import { useTranslation } from "common/i18n";

import { trackUpperRightMenuClickEvent } from "./trackUpperRightMenuClickEvent";
import { MenuLink, NewIcon } from "./IconButtons.styled";

export const FavouriteButton = () => {
  const { t } = useTranslation();
  const favourites = useFavourites();

  return (
    <MenuLink
      route="favourites"
      title={t("navigation.favourites")}
      onClick={() => trackUpperRightMenuClickEvent("favourites")}
    >
      <IconWithBadge value={favourites.count}>
        <NewIcon icon="heart" width="24" height="24" />
      </IconWithBadge>
    </MenuLink>
  );
};
