export const config = {
  columns: [
    {
      title: "footer.help",
      expandable: true,
      links: [
        {
          route: "helpCenterWithQuestion",
          params: {
            categoryId: "inne-zagadnienia",
            questionId: "kontakt",
          },
          text: "footer.contact",
        },
        { route: "helpCenter", text: "footer.help_center_contact" },
        {
          href: "https://static.audioteka.com/pl/files/regulamin/Regulamin_serwisu_Audioteka.pdf",
          text: "footer.terms_and_conditions",
        },
        {
          href: "https://web.audioteka.com/legal/pl/Regulamin-Audioteka-Klub.pdf",
          text: "footer.audioteka_club_tos",
        },
        {
          href: "https://web.audioteka.com/legal/pl/Regulamin-Audioteka-Plus.pdf",
          text: "footer.audioteka_plus_tos",
        },
        { route: "privacyPolicy", text: "footer.privacy_policy" },
        { className: "manageGdprButton", text: "footer.privacy_settings" },
      ],
    },
    {
      title: "footer.start_listening",
      expandable: true,
      links: [
        { href: "https://web.audioteka.com/pl/start.html", text: "footer.meet_audioteka" },
        { href: "https://pages.audioteka.com/pl/audioteka-klub/", text: "footer.club" },
        { href: "https://pages.audioteka.com/pl/karnety/", text: "footer.coupons" },
        { href: "https://cards.audioteka.com/", text: "footer.gift_cards" },
        { href: "https://pages.audioteka.com/pl/aktywuj-karte/", text: "footer.activate_card" },
        { href: "https://pages.audioteka.com/pl/dla-firm", text: "footer.for_companies" },
      ],
    },
    {
      title: "footer.useful_links",
      expandable: true,
      links: [
        { href: "https://pages.audioteka.com/pl/uslysz-kulture/", text: "footer.uslysz_kulture" },
        { href: "https://welcome.audioteka.com/", text: "footer.choose_language" },
      ],
    },
    {
      title: "footer.applications",
      apps: {
        android: "https://web.audioteka.com/pl/l/android",
        ios: "https://web.audioteka.com/pl/l/ios",
      },
      links: [{ href: "https://audioteka.com/pl/applications", text: "footer.all_apps" }],
    },
  ],
  social: [
    {
      href: "https://www.facebook.com/audioteka",
      icon: "facebook",
    },
    {
      href: "https://www.youtube.com/user/audiotekapl",
      icon: "youtube",
    },
    {
      href: "https://www.instagram.com/audioteka/",
      icon: "instagram",
    },
    {
      href: "https://twitter.com/audioteka",
      icon: "twitter",
    },
  ],
  copy: `Copyright © 2008-${new Date().getFullYear()} Audioteka Group Sp. z o.o.`,
};
