export const pathnames = {
  audiobook: {
    pl: "/audiobook/[id]",
    en: "/audiobook/[id]",
  },
  audiotekaPlus: {
    pl: "/audioteka-plus",
    en: "/audioteka-plus",
  },
  author: {
    pl: "/autor/[id]",
    en: "/author/[id]",
  },
  applications: {
    pl: "/aplikacje",
    en: "/applications",
  },
  cart: {
    pl: "/koszyk",
    en: "/cart",
  },
  catalog: {
    pl: "/katalog",
    en: "/catalog",
  },
  category: {
    pl: "/katalog/[id]",
    en: "/catalog/[id]",
  },
  cycle: {
    pl: "/cykl/[id]",
    en: "/cycle/[id]",
  },
  favourites: {
    pl: "/ulubione",
    en: "/favourites",
  },
  helpCenter: {
    pl: "/centrum-pomocy",
    en: "/help-center",
  },
  helpCenterWithCategory: {
    pl: "/centrum-pomocy/[categoryId]",
    en: "/help-center/[categoryId]",
  },
  helpCenterWithQuestion: {
    pl: "/centrum-pomocy/[categoryId]/[questionId]",
    en: "/help-center/[categoryId]/[questionId]",
  },
  home: {
    pl: "/",
    en: "/",
  },
  lector: {
    pl: "/lektor/[id]",
    en: "/lector/[id]",
  },
  passwordReset: {
    pl: "/reset-hasla",
    en: "/password-reset",
  },
  player: {
    pl: "/player/[id]",
    en: "/player/[id]",
  },
  podcast: {
    pl: "/podcast/[id]",
    en: "/podcast/[id]",
  },
  privacyPolicy: {
    pl: "/polityka-prywatnosci",
    en: "/privacy-policy",
  },
  publisher: {
    pl: "/wydawca/[id]",
    en: "/publisher/[id]",
  },
  recentlyPlayed: {
    pl: "/ostatnio-sluchane",
    en: "/recently-played",
  },
  reviewTerms: {
    pl: "/zasady-opiniowania",
    en: "/review-terms",
  },
  search: {
    pl: "/szukaj/[query]",
    en: "/search/[query]",
  },
  shelf: {
    pl: "/polka",
    en: "/shelf",
  },
  signIn: {
    pl: "/zaloguj",
    en: "/sign-in",
  },
  signUp: {
    pl: "/zarejestruj",
    en: "/sign-up",
  },
  userPanel: {
    pl: "/panel-uzytkownika",
    en: "/user-panel",
  },
  userPanel_consents: {
    pl: "/panel-uzytkownika/zgody",
    en: "/user-panel/consents",
  },
  userPanel_familySharing: {
    pl: "/panel-uzytkownika/podziel-sie",
    en: "/user-panel/family-sharing",
  },
  userPanel_profile: {
    pl: "/panel-uzytkownika/profil",
    en: "/user-panel/profile",
  },
  userPanel_sessions: {
    pl: "/panel-uzytkownika/sesje",
    en: "/user-panel/sessions",
  },
} as const;
