import React, { useEffect, useMemo, useRef, useState } from "react";
import ReactDOM from "react-dom";

import { isServer } from "utils/runtime";

import { Close, Content, Header, Logo, Root } from "./MenuMobile.styled";

const HeaderComp = ({ children, onClose }: { children: React.ReactNode; onClose: () => void }) => (
  <Header>
    <Logo />
    <Close name="close" onClick={onClose} size={28} />
    {children}
  </Header>
);

const ContentComp = ({ children, size, open }: { open: boolean; size: number; children: React.ReactNode }) => {
  const contentRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (open && contentRef.current) {
      contentRef.current.scrollTo({
        behavior: "auto",
        top: 0,
      });
    }
  }, [open]);

  return (
    <Content size={size} ref={contentRef}>
      {children}
    </Content>
  );
};

const MenuMobile = ({ open, children }: { open: boolean; children: React.ReactNode }) => {
  const [isMounted, setMounted] = useState(false);
  const root = useMemo(() => (isServer ? null : document.createElement("div")), []);

  useEffect(() => {
    document.body.append(root);
    setMounted(true);

    return () => {
      setMounted(false);
      document.body.removeChild(root);
    };
  }, []);

  return isMounted ? ReactDOM.createPortal(<Root open={open}>{children}</Root>, root) : null;
};

MenuMobile.Header = HeaderComp;
MenuMobile.Content = ContentComp;

export default MenuMobile;
