import React, { useCallback, useEffect, useRef, useState } from "react";
import debounce from "lodash/debounce";
import { HalController } from "api-web-client";
import gtm from "react-gtm-module";

import { deeplinkTranslator } from "modules/App/deeplinkTranslator";

import Container from "modules/DesignSystem/components/Container";
import Teaser from "modules/DesignSystem/components/Teaser";
import SvgIcon from "modules/DesignSystem/components/Icon";
import { getGTMProduct, setSelectedItemInLocalStorage } from "utils/products";
import { useTeaserTracking } from "modules/teaser-tracking";
import { getAudiobook } from "resources/AudiotekaApi";

import { Arrow, Carousel, CarouselSlider, More, SliderItem, Title, TitleContainer } from "./styled";

interface Props {
  className?: string;
  colorless?: boolean;
  compact?: boolean;
  dark?: boolean;
  elements: (HalController<HALProduct> | HALProduct)[];
  id?: string;
  lazyLoading?: boolean;
  more?: {
    link: string;
    text: string;
  };
  noPlayer?: boolean;
  noPrice?: boolean;
  title?: string;
  trackingId?: string;
}

const TeaserList = ({
  className,
  colorless,
  compact,
  dark,
  title,
  lazyLoading,
  more,
  elements,
  noPlayer,
  noPrice,
  trackingId,
}: Props) => {
  const prevRef = useRef<HTMLButtonElement>(null);
  const nextRef = useRef<HTMLButtonElement>(null);
  const sliderRef = useRef<HTMLDivElement>(null);

  const teaserTracking = useTeaserTracking();

  const [arrowState, setArrowState] = useState({
    hasScrollRight: false,
    hasScrollLeft: false,
  });

  const performScroll = (newPos: number) => {
    sliderRef.current?.scrollTo({
      left: newPos,
      behavior: "smooth",
    });
  };

  const onNextClick = () => {
    const { width } = sliderRef.current.getBoundingClientRect();
    performScroll(sliderRef.current.scrollLeft + width);
  };

  const onPrevClick = () => {
    const { width } = sliderRef.current.getBoundingClientRect();
    performScroll(sliderRef.current.scrollLeft - width);
  };

  const gtmClickTracking = useCallback(
    async (product: HalController<HALProduct> | HALProduct) => {
      if (!trackingId) {
        return;
      }

      const isAudiobook = /\/audiobook\//.test(product.data.deeplink);
      const audiobook = isAudiobook ? await getAudiobook(product.data.id) : null;

      gtm.dataLayer({
        dataLayer: {
          event: "select_item",
          ecommerce: {
            item_list_id: trackingId,
            item_list_name: title,
            items: [
              getGTMProduct({
                audiobook: audiobook ? HalController.fromObject(audiobook as any) : null,
                product,
              }),
            ],
          },
        },
      });
    },
    [elements, trackingId, title]
  );

  const onTeaserClick = (product: HalController<HALProduct> | HALProduct) => {
    if (trackingId && title) {
      setSelectedItemInLocalStorage(product.data.id, { id: trackingId, name: title });
    }
    gtmClickTracking(product);
  };

  const onSliderScroll = useCallback(
    debounce(() => {
      if (!sliderRef.current) {
        return;
      }

      const { scrollLeft, scrollWidth, offsetWidth } = sliderRef.current;
      const hasScrollRight = scrollLeft + offsetWidth < scrollWidth;
      const hasScrollLeft = scrollLeft > 0;
      setArrowState({
        hasScrollRight,
        hasScrollLeft,
      });
    }, 100),
    []
  );

  useEffect(() => {
    onSliderScroll();
  }, []);

  useEffect(() => {
    sliderRef.current?.scrollTo({ left: 0, behavior: "auto" });
  }, [elements]);

  useEffect(() => {
    teaserTracking.addList(
      trackingId,
      elements.map((teaser) => teaser.data.id)
    );

    return () => {
      teaserTracking.removeList(trackingId);
    };
  }, [elements, teaserTracking, trackingId]);

  return (
    <Container className={className}>
      {title && (
        <TitleContainer>
          <Title>{title}</Title>
          {more && <More {...deeplinkTranslator(more.link)}>{more.text} &gt;</More>}
        </TitleContainer>
      )}
      <Carousel className="adtk-item-list" data-list-id={trackingId} data-list-name={title}>
        <CarouselSlider compact={compact} ref={sliderRef} onScroll={onSliderScroll}>
          {elements.map((teaser) => (
            <SliderItem key={teaser.data.id} compact={compact}>
              <Teaser
                action={noPlayer ? undefined : "play"}
                colorless={colorless}
                dark={dark}
                lazyLoading={lazyLoading}
                onClick={() => onTeaserClick(teaser)}
                noPrice={noPrice}
                product={teaser}
              />
            </SliderItem>
          ))}
        </CarouselSlider>
        <Arrow compact={compact} left disabled={!arrowState.hasScrollLeft} ref={prevRef} onClick={onPrevClick}>
          <SvgIcon name="arrow" flipX size={30} />
        </Arrow>
        <Arrow compact={compact} right disabled={!arrowState.hasScrollRight} ref={nextRef} onClick={onNextClick}>
          <SvgIcon name="arrow" size={30} />
        </Arrow>
      </Carousel>
    </Container>
  );
};

export default TeaserList;
