import React, { PropsWithChildren } from "react";
import Badge from "modules/DesignSystem/components/Badge";
import styled from "styled-components";

interface Props extends PropsWithChildren {
  value: string | number;
}

const IconWrapper = styled.div`
  position: relative;

  & > ${Badge} {
    position: absolute;
    top: -4px;
    left: 50%;
  }
`;

const IconWithBadge: React.FC<Props> = ({ children, value }) => (
  <IconWrapper>
    <Badge value={value} />
    {children}
  </IconWrapper>
);

export default IconWithBadge;
