import styled, { css } from "styled-components";

import Container from "modules/DesignSystem/components/Container";
import LinkBase from "components/link";
import { MEDIA_QUERY } from "modules/DesignSystem/breakpoint";
import ButtonPrimitive from "modules/DesignSystem/components/ButtonPrimitive";

export const Bottom = styled.div`
  padding: 24px 0;
  text-align: center;

  &::before {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    content: "";
    display: block;
    margin: 0 auto;
    max-width: 510px;
    width: calc(100% - 30px);
  }

  p {
    font-size: 15px;
    margin: 0;
  }

  @media ${MEDIA_QUERY.md} {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    padding: 0;

    &::before {
      display: none;
    }
  }

  @media ${MEDIA_QUERY.lg} {
    padding: 12px 0;
  }
`;

export const BottomContainer = styled(Container)`
  align-items: center;
  display: flex;
  flex-direction: column;

  @media ${MEDIA_QUERY.md} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const Column = styled.div`
  align-content: start;
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr;

  @media ${MEDIA_QUERY.xl} {
    flex: 1;
  }
`;

export const Columns = styled(Container)`
  display: grid;
  grid-gap: 18px;
  grid-template-columns: 1fr;
  margin: 0 auto;

  @media ${MEDIA_QUERY.md} {
    grid-template-columns: 1fr 1fr;
  }

  @media ${MEDIA_QUERY.xl} {
    display: flex;
  }
`;

export const IconLink = styled(LinkBase)`
  color: #8d889e;
  display: flex;

  &:hover {
    color: #fff;
  }
`;

export const AppLinkList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const AppLink = styled(LinkBase)`
  display: flex;
  width: 162px;
`;

export const Link = styled(LinkBase)`
  color: inherit;
  display: block;
  text-decoration: none;
  font-size: 16px;

  &:hover {
    opacity: 0.6;
  }
`;

export const LinkBtn = styled(ButtonPrimitive)`
  display: block;
  font-size: 16px;
  text-align: left;

  &:hover {
    opacity: 0.6;
  }
`;

export const LinkList = styled.div<{ expanded?: boolean }>(({ expanded }) => {
  if (typeof expanded === "boolean") {
    return css`
      border-bottom: solid 1px rgba(255, 255, 255, 0.2);
      display: grid;
      gap: 12px;
      grid-template-columns: 1fr;
      height: ${expanded ? "auto" : 0};
      overflow: hidden;
      padding-bottom: ${expanded ? "18px" : 0};

      @media ${MEDIA_QUERY.md} {
        border-bottom: 0;
        height: auto;
        padding-bottom: 18px;
      }
    `;
  }

  return css`
    display: flex;
    flex-direction: column;
    grid-gap: 12px;
  `;
});

export const Title = styled.h3<{ expanded?: boolean }>(({ expanded }) => {
  if (typeof expanded === "boolean") {
    return css`
      align-items: center;
      cursor: pointer;
      display: flex;
      font-size: 16px;
      font-weight: 700;
      justify-content: space-between;
      margin: 0;

      &::after {
        border: solid currentColor;
        border-width: 0 2px 2px 0;
        content: "";
        display: block;
        height: 10px;
        transform: ${expanded ? "translateY(25%) rotateZ(-135deg)" : "translateY(-25%) rotateZ(45deg)"};
        transition: all 0.2s ease;
        width: 10px;
      }

      @media ${MEDIA_QUERY.md} {
        font-size: 20px;

        &::after {
          cursor: default;
          display: none;
        }
      }
    `;
  }

  return css`
    font-size: 16px;
    font-weight: 700;
    margin: 0;

    @media ${MEDIA_QUERY.md} {
      font-size: 20px;
    }
  `;
});

export const Wrapper = styled.div`
  background: #160829;
  color: #fff;
  display: flex;
  flex-direction: column;

  @media ${MEDIA_QUERY.md} {
    grid-gap: 28px;
  }
`;
