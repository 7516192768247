import React from "react";
import { useTranslation } from "react-i18next";
import { useRouter } from "next/router";

import { NewIcon } from "components/NewIcon";
import { useFavourites } from "modules/Favourites/FavouritesProvider";

import { useCart } from "../../../cart.hooks";
import { CartItem } from "../../../cart.types";
import { joinPriceWithCurrency } from "../../../cart.utils";
import {
  Action,
  Actions,
  Description,
  Image,
  ImageLink,
  Name,
  Price,
  PriceLowest,
  PriceOld,
  Root,
} from "./cart-product.styled";

interface Props {
  product: CartItem;
  isFavourite: boolean;
}

export function CartProduct({ product: { audiobook, details }, isFavourite }: Props) {
  const { currency, removeFromCart } = useCart();
  const { dislike, like } = useFavourites();
  const { locale } = useRouter();
  const { t } = useTranslation();

  const finalPrice = joinPriceWithCurrency(details.final_user_price / 100, currency, locale);
  const basePrice = joinPriceWithCurrency(details.price / 100, currency, locale);

  return (
    <Root>
      <ImageLink route="audiobook" params={{ id: audiobook.data.slug }}>
        <Image src={`${audiobook.data.image_url}?w=200&auto=format`} />
      </ImageLink>
      <Name route="audiobook" params={{ id: audiobook.data.slug }}>
        {audiobook.data.name}
      </Name>
      <Description>{audiobook.data.description}</Description>
      <Price>
        {finalPrice}
        {details.final_user_price !== details.price && <PriceOld>{basePrice}</PriceOld>}
      </Price>
      {audiobook.data.lowest_price && (
        <PriceLowest>{t("cart.lowest_price", { price: audiobook.data.lowest_price })}</PriceLowest>
      )}
      <Actions>
        <Action onClick={() => (isFavourite ? dislike(details.id) : like(details.id))}>
          <NewIcon icon={isFavourite ? "heart" : "heart-border"} width="16" height="16" />
        </Action>
        <Action onClick={() => removeFromCart(details.id)}>
          <NewIcon icon="trash" width="16" height="16" />
        </Action>
      </Actions>
    </Root>
  );
}
