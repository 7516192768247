import React, { PropsWithChildren } from "react";
import styled from "styled-components";

import ScrollTop from "./ScrollTop";
import TopBar from "./TopBar";
import { MenuMobileItem } from "./MenuMobile";
import MobileAppBanner from "./MobileAppBanner";
import Footer from "./Footer";

const MainSpace = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Content = styled.div`
  flex: 1;
`;

export function Layout({ children, catalogItems }: PropsWithChildren<{ catalogItems: MenuMobileItem[] }>) {
  return (
    <>
      <MainSpace>
        <TopBar catalogItems={catalogItems} />
        <Content>{children}</Content>
        <Footer catalogItems={catalogItems} />
        <ScrollTop />
      </MainSpace>
      <MobileAppBanner />
    </>
  );
}
