import styled, { css } from "styled-components";

import { breakpoint, MEDIA_QUERY } from "modules/DesignSystem/breakpoint";
import Link from "components/link";

export const Container = styled.div<{ open: boolean }>`
  display: none;

  @media (min-width: ${breakpoint.tablet}) {
    background: #160829;
    border-radius: 0 0 8px 8px;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2);
    display: flex;
    flex-direction: column;
    height: auto;
    left: 0;
    overflow: hidden;
    position: absolute;
    padding: 0;
    right: 0;
    top: 100%;
    ${({ open }) => css`
      transform: ${open ? "translateY(0)" : "translateY(-101%)"};
      visibility: ${open ? "visible" : "hidden"};
    `}
    transition: transform .2s ease, visibility .2s ease;
    width: auto;
    z-index: -1;
  }
`;

export const List = styled.div<{ itemsCount: number }>`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 16px;
  justify-items: start;
  height: 100%;
  overflow: auto;
  overscroll-behavior: contain;
  padding-bottom: 16px;

  ${({ itemsCount }) => css`
    @media (min-width: ${breakpoint.tablet}) {
      height: auto;
      grid-auto-flow: column;
      grid-template-rows: repeat(${Math.ceil(itemsCount / 2)}, auto);
      padding: 24px 24px 16px;
    }

    @media ${MEDIA_QUERY.lg} {
      grid-template-rows: repeat(${Math.ceil(itemsCount / 3)}, auto);
    }

    @media ${MEDIA_QUERY.xl} {
      grid-template-rows: repeat(${Math.ceil(itemsCount / 4)}, auto);
    }
  `}
`;

export const Item = styled(Link)<{ accent?: boolean }>`
  color: ${({ accent }) => (accent ? "#33adff" : "#fff")};

  &:hover {
    color: #9dd7ff;
  }
`;

export const Footer = styled.div`
  background: #24113e;
  display: flex;
  height: 40px;
  justify-content: space-between;
  padding: 5px 24px;
`;

const footerItemStyles = css`
  align-items: center;
  color: #bbb7c5;
  cursor: pointer;
  display: grid;
  gap: 10px;
  grid-template-columns: auto 1fr;

  &:hover {
    color: #fff;
  }
`;

export const CategoriesLink = styled(Link)`
  ${footerItemStyles}
  text-decoration: none;
`;

export const CollapseButton = styled.button`
  ${footerItemStyles}
  padding: 0;
  background: none;
  border: none;
`;
