import styled from "styled-components";

// eslint-disable-next-line import/prefer-default-export
export const ListStyled = styled.ul`
  display: grid;
  grid-gap: 20px;
  grid-auto-flow: column;
  list-style: none;
  padding-left: 0;
  align-items: center;

  & > * {
    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`;
