import React, { ComponentProps, FC, PropsWithChildren, ReactElement, useMemo, useRef, useState } from "react";

import { Root, TabLink, TabLinks } from "./tabs.styled";

/*
 * Tab
 */

interface TabProps extends PropsWithChildren {
  // eslint-disable-next-line react/no-unused-prop-types
  name: string;
}

export const Tab: FC<TabProps> = ({ children }) => {
  return <div>{children}</div>;
};

/*
 * TabList
 */

type TabElement = ReactElement<ComponentProps<typeof Tab>>;

interface TabListProps {
  children: TabElement[];
}

export const TabList: FC<TabListProps> = ({ children }) => {
  const tabLinksRef = useRef<Map<string | number, HTMLElement>>(new Map());

  const tabs = useMemo(() => children.filter((child) => child.type === Tab), [children]);

  const [activeTabKey, setActiveTabKey] = useState<string | number>(tabs[0].key || tabs[0].props.name);

  const activeTab = tabs.find((tab) => (tab.key || tab.props.name) === activeTabKey);
  const activeLinkElement = tabLinksRef.current.get(activeTabKey);

  return (
    <Root>
      <TabLinks activeLeft={activeLinkElement?.offsetLeft} activeWidth={activeLinkElement?.offsetWidth}>
        {tabs.map((tab) => (
          <TabLink
            key={tab.key || tab.props.name}
            ref={(element) => tabLinksRef.current.set(tab.key || tab.props.name, element)}
            onClick={() => setActiveTabKey(tab.key || tab.props.name)}
          >
            {tab.props.name}
          </TabLink>
        ))}
      </TabLinks>
      <div>{activeTab}</div>
    </Root>
  );
};
