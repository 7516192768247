import styled from "styled-components";

import ButtonPrimitive from "modules/DesignSystem/components/ButtonPrimitive";
import { Link } from "components";

export const Actions = styled.div`
  align-items: center;
  background: repeating-linear-gradient(90deg, #0000, #0000 27px, #989898 27px, #989898 28px);
  display: flex;
  flex-wrap: nowrap;
  gap: 16px;
  grid-column: 3;
  grid-row: 3;
  justify-content: end;
  justify-self: end;
  padding: 2px;
`;

export const Action = styled(ButtonPrimitive)`
  color: #989898;
  height: 16px;
  width: 16px;
`;

export const Description = styled.p`
  color: #989898;
  font-size: 0.875rem;
  grid-column: 2;
  grid-row: 2;
  line-height: 1.4;
  margin: 0;
`;

export const Image = styled.img`
  border-radius: 4px;
  width: 100%;
`;

export const ImageLink = styled(Link)`
  grid-column: 1;
  grid-row: 1/3;
`;

export const Name = styled(Link)`
  color: inherit;
  font-size: 0.875rem;
  grid-column: 2;
  grid-row: 1;
  line-height: 1.4;
`;

export const Price = styled.p`
  align-items: start;
  display: grid;
  font-weight: 700;
  gap: 8px;
  grid-column: 3;
  grid-row: 1/3;
  justify-items: end;
  margin: 0;
  text-align: right;
  white-space: nowrap;
`;

export const PriceLowest = styled.p`
  align-self: center;
  color: #989898;
  grid-column: 1/3;
  grid-row: 3;
  font-size: 0.625rem;
`;

export const PriceOld = styled.span`
  color: #989898;
  font-size: 0.85em;
  font-weight: 400;
  text-decoration: line-through;
`;

export const Root = styled.li`
  display: grid;
  gap: 0 8px;
  grid-template-columns: 80px 1fr auto;
  grid-template-rows: auto 1fr auto;
  padding: 16px 0;

  &:not(:last-child) {
    border-bottom: 1px solid #bbb7c5;
  }
`;
