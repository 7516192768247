import styled from "styled-components";

import { Breakpoint } from "modules/DesignSystem/breakpoint";
import TeaserListBase from "modules/DesignSystem/components/TeaserList";

export const Results = styled.div`
  background: #fff;
  padding: 16px 0;
  max-height: 100%;
  overflow-y: auto;
  overscroll-behavior: contain;

  @media (min-width: ${Breakpoint.lg}px) {
    padding: 32px 0;
    border-radius: 0px 0px 16px 16px;
  }
`;

export const TeaserList = styled(TeaserListBase)`
  display: none;

  @media (min-width: ${Breakpoint.lg}px) {
    display: block;
  }
`;

export const MobileGrid = styled.div`
  display: grid;
  flex-wrap: wrap;
  grid-gap: 24px 16px;
  grid-template-columns: repeat(auto-fit, 164px);
  justify-content: center;
  list-style: none;
  position: relative;
  overflow: auto;

  @media (min-width: ${Breakpoint.lg}px) {
    display: none;
  }
`;
