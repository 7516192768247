import React, { ComponentProps } from "react";
import api from "api-web-client";
import App, { AppContext } from "next/app";
import Head from "next/head";
import Script from "next/script";
import Router, { useRouter } from "next/router";

import { setUser } from "modules/user";
import { AppContainer, getDeviceID } from "modules/App";
import { AppProviders } from "modules/App/AppProviders";
import { i18n } from "common/i18n/config";
import { getScreenCatalog } from "resources/AudiotekaApi";
import { getCatalogItems } from "utils/getCatalogItems";
import { isServer } from "utils/runtime";
import { appWithTranslation, useSSR, useTranslation } from "common/i18n";
import { getLocationInfo } from "utils/getLocationInfo";

// Global styles needs to be imported in _app.tsx (NextJS)
import "shaka-player/dist/controls.css";
import "./styles/_normalize.css";
import "./styles/_default.css";
import "./styles/_scrollbar.css";

api.onMe = setUser;

api.initialize(process.env.API_URL, {
  command: "RefreshTokenForWeb",
  payload: {
    device_id: getDeviceID(),
  },
});

if (!isServer) {
  api.subscribe(({ commandName, response }) => {
    if (commandName === "RefreshTokenForWeb" && response.status === 422) {
      // Failed to refresh token - logout user.
      setUser(null);
    }
  });
}

function AudiotekaApp(props: ComponentProps<typeof AppContainer>) {
  const { t } = useTranslation();
  const { locale, asPath } = useRouter();

  const { _canonical = `/${locale}${asPath.replace(/\?(.*)$/, "")}`, _nextI18Next, _robots = "" } = props.pageProps;

  if (_nextI18Next?.initialI18nStore && _nextI18Next?.initialLocale) {
    useSSR(_nextI18Next.initialI18nStore, _nextI18Next.initialLocale);
  }

  return (
    <>
      <Head>
        <title>{t("meta.home.title")}</title>
        <meta name="robots" content={`${_robots && `${_robots}, `}max-image-preview:large`} />
        <meta name="description" content={t("meta.home.description")} />
        <meta key="og-title" property="og:title" content={t("meta.home.title")} />
        <meta key="og-description" property="og:description" content={t("meta.home.description")} />
        <meta
          key="og-image"
          property="og:image"
          content="https://fn.audioteka.com/pl/go/ogimage.jpg?locale=pl&v=2020-12-15"
        />
        <link key="canonical" rel="canonical" href={`${process.env.SITE_URL}${_canonical}`} />
      </Head>

      <AppProviders>
        <AppContainer {...props} />
      </AppProviders>

      <Script id="wpjslib-config">
        {`
          var wp_onepager = true;
          var wp_sn = 'audioteka';
          var wp_consent_color = '#33ADFF';
          var wp_consent_text = '<strong>Audioteka</strong> jest częścią holdingu Wirtualna Polska.<br><br>';
          var wp_consent_logo = 'https://atkcdn.com/audioteka/global/logo-transparent.png';
          var wp_consent_blur = 0;
          window.wp_cookie_info = false;
        `}
      </Script>
      <Script async crossOrigin="true" noModule defer src="https://std.wpcdn.pl/wpjslib/wpjslib-lite.js" id="wpjslib" />
      <Script
        async
        crossOrigin="true"
        type="module"
        src="https://std.wpcdn.pl/wpjslib6/wpjslib-lite.js"
        id="wpjslib6"
      />
    </>
  );
}

AudiotekaApp.getInitialProps = async (appContext: AppContext) => {
  const { req, res, locale } = appContext.ctx;

  if (appContext.router.locale === "default") {
    if (res) {
      res.writeHead(308, `/${i18n.defaultLocale}${req.url}`);
      res.end();
    } else {
      Router.replace(`/pl${req.url}`);
    }
    return { disableRender: true };
  }

  const { catalogId } = getLocationInfo(locale);

  const appProps = await App.getInitialProps(appContext);
  const catalog = await getScreenCatalog(catalogId);

  if (req) {
    return {
      ...appProps,
      catalogId,
      catalogItems: getCatalogItems(catalog),
    };
  }

  return appProps;
};

export default appWithTranslation(AudiotekaApp);
