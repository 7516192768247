import styled, { css } from "styled-components";
import TitleBase from "modules/DesignSystem/components/Title";
import { breakpoint, GUTTER, MEDIA_QUERY } from "modules/DesignSystem/breakpoint";
import { Link } from "components";

export const CarouselSlider = styled.div<{ compact?: boolean }>`
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  margin-right: -15px;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ${({ compact }) =>
    compact
      ? css`
          grid-gap: 16px;

          @media (min-width: ${breakpoint.desktop}) {
            grid-gap: 24px;
          }
        `
      : css`
          grid-gap: 15px;
        `}
`;

export const Arrow = styled.button<{ compact?: boolean; right?: boolean; left?: boolean; inactive?: boolean }>`
  align-items: center;
  backdrop-filter: blur(200px);
  background: rgba(255, 255, 255, 0.5);
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  display: none;
  height: 46px;
  justify-content: center;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 124px;
  transform: translateY(-50%);
  transition: opacity 0.5s ease;
  width: 46px;
  z-index: 1;

  &:disabled {
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
  }

  ${(props) =>
    props.right &&
    css`
      right: -24px;
    `}
  ${(props) =>
    props.left &&
    css`
      left: -24px;
    `}
    svg {
    color: #000000;
  }

  @media screen and ${MEDIA_QUERY.sm} {
    display: flex;
  }

  ${({ compact }) =>
    compact
      ? css`
          top: 70px;

          @media (min-width: ${breakpoint.desktop}) {
            top: 90px;
          }
        `
      : css`
          @media screen and ${MEDIA_QUERY.md} {
            top: 110px;
          }
          @media screen and ${MEDIA_QUERY.lg} {
            top: 87px;
          }
          @media screen and ${MEDIA_QUERY.xl} {
            top: 105px;
          }
          @media screen and ${MEDIA_QUERY.xxl} {
            top: 116px;
          }
        `}
`;

export const Carousel = styled.div`
  position: relative;
`;

export const More = styled(Link)`
  color: #33adff;
  display: block;
  font-size: 16px;

  @media screen and ${MEDIA_QUERY.md} {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const SliderItem = styled.div<{ compact?: boolean }>`
  display: flex;
  flex-shrink: 0;
  position: relative;
  z-index: 0;
  scroll-snap-align: start;

  > * {
    flex: 1;
  }

  ${({ compact }) =>
    compact
      ? css`
          width: 140px;

          @media (min-width: ${breakpoint.desktop}) {
            width: 180px;
          }
        `
      : css`
          width: 220px;

          @media ${MEDIA_QUERY.sm} {
            min-width: 50%;
            flex-shrink: 1;
          }
          @media ${MEDIA_QUERY.md} {
            min-width: 33.3333%;
          }
          @media ${MEDIA_QUERY.lg} {
            min-width: 20%;
          }
          @media ${MEDIA_QUERY.xxl} {
            min-width: 16.6666%;
          }
        `}
`;

export const Title = styled(TitleBase)`
  margin: 0 !important;
`;

export const TitleContainer = styled.div`
  align-items: center;
  display: flex;
  grid-gap: ${GUTTER / 2}px;
  margin-bottom: 16px;
  position: relative;

  > *:first-child {
    flex: 1;
  }

  @media ${MEDIA_QUERY.lg} {
    margin-bottom: 26px;
  }
`;
