import { ComponentProps, ComponentType, createElement } from "react";

import { useUser } from "./user.hooks";
import { WithUser } from "./user.types";

export const withUser =
  <C extends ComponentType<ComponentProps<C> & WithUser>>(component: C) =>
  (props): JSX.Element => {
    const user = useUser();

    return createElement(component, { ...props, user });
  };
