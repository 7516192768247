import React, { PropsWithChildren, ReactElement, useCallback, useLayoutEffect, useRef } from "react";

import Icon from "modules/DesignSystem/components/Icon";
import { MEDIA_QUERY } from "modules/DesignSystem/breakpoint";

import { SwiperBtn, SwiperRoot, SwiperWrapper } from "./styled";

interface Props extends PropsWithChildren {
  arrowsBreakpoint?: keyof typeof MEDIA_QUERY;
  center?: boolean;
  gap?: number;
}

export default function Swiper({ arrowsBreakpoint, center, children, gap }: Props): ReactElement {
  /*
   * Refs
   */
  const previousBtnRef = useRef<HTMLButtonElement>();
  const nextBtnRef = useRef<HTMLButtonElement>();
  const wrapperRef = useRef<HTMLDivElement>();

  /*
   * Effects
   */
  useLayoutEffect(() => {
    function checkButtons() {
      const { current: wrapper } = wrapperRef;
      const { current: previousBtn } = previousBtnRef;
      const { current: nextBtn } = nextBtnRef;

      if (!wrapper) {
        return;
      }

      if (previousBtn) {
        previousBtn.disabled = wrapper.scrollLeft <= 0;
      }

      if (nextBtn) {
        nextBtn.disabled = wrapper.scrollLeft >= wrapper.scrollWidth - wrapper.offsetWidth;
      }
    }

    if (wrapperRef.current) {
      wrapperRef.current.addEventListener("scroll", checkButtons);
    }

    window.addEventListener("resize", checkButtons);

    checkButtons();

    return () => {
      window.removeEventListener("resize", checkButtons);

      if (wrapperRef.current) {
        wrapperRef.current.removeEventListener("scroll", checkButtons);
      }
    };
  }, []);

  /*
   * Callbacks
   */
  const changeSwipe = useCallback((direction: number) => {
    const { current: wrapper } = wrapperRef;

    if (!wrapper || !wrapper.firstElementChild) {
      return;
    }

    const width =
      (wrapper.firstElementChild as HTMLElement).offsetWidth || wrapper.firstElementChild.getBoundingClientRect().width;

    wrapper.scrollBy({ behavior: "smooth", left: width * direction });
  }, []);

  const prevSwipe = useCallback(() => changeSwipe(-1), []);
  const nextSwipe = useCallback(() => changeSwipe(1), []);

  /*
   * Render
   */
  return (
    <SwiperRoot arrowsBreakpoint={arrowsBreakpoint}>
      <SwiperBtn direction="previous" onClick={prevSwipe} ref={previousBtnRef}>
        <Icon name="chevron-left" size={24} />
      </SwiperBtn>
      <SwiperBtn direction="next" onClick={nextSwipe} ref={nextBtnRef}>
        <Icon name="chevron-right" size={24} />
      </SwiperBtn>
      <SwiperWrapper center={center} gap={gap} ref={wrapperRef}>
        {children}
      </SwiperWrapper>
    </SwiperRoot>
  );
}
