import styled from "styled-components";

import { Link as BaseLink } from "components/link";
import ButtonPrimitive from "modules/DesignSystem/components/ButtonPrimitive";

import { titleStyles } from "../../../MenuMobile";

export const Title = styled.div`
  ${titleStyles}
`;

export const Description = styled.p`
  font-size: 0.75em;
  font-weight: 400;
  margin: 0;
  opacity: 0.5;
`;

export const Link = styled(BaseLink)`
  align-items: center;
  color: #fff;
  display: grid;
  font-weight: 700;
  gap: 16px;
  grid-template-columns: auto 1fr;
  text-align: left;
  text-decoration: none;

  svg {
    color: #33adff;
  }
`;

export const LogoutButtonWrapper = styled.div`
  align-items: center;
  align-self: start;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  padding: 40px 0 74px;
`;

export const LogoutButton = styled(ButtonPrimitive)`
  align-items: center;
  color: #bbb7c5;
  display: grid;
  gap: 10px;
  grid-template-columns: auto 1fr;
  text-align: left;

  svg {
    color: inherit;
  }
`;
