import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const Root = styled.div`
  padding: 16px;
  text-align: center;
`;

export function Empty() {
  const { t } = useTranslation();

  return <Root>{t("cart.empty")}</Root>;
}
