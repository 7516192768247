import React from "react";

import Teaser from "modules/DesignSystem/components/Teaser";

import { MobileGrid, Results, TeaserList } from "./SearchAndRecommendationResults.styled";

type Props = {
  results: HALProduct[];
};

export const SearchResults = ({ results }: Props) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const resultsHash = results.map((product) => product.data.id).join("-");

  React.useEffect(() => {
    if (ref.current) {
      ref.current.scrollTo({
        top: 0,
      });
    }
  }, [resultsHash]);

  return (
    <Results ref={ref}>
      <MobileGrid>
        {results.map((product) => (
          <Teaser key={product.data.id} product={product} />
        ))}
      </MobileGrid>
      <TeaserList elements={results} />
    </Results>
  );
};
