const i18n = require("i18next");
const { initReactI18next } = require("react-i18next");
const Backend = require("i18next-http-backend");
const LanguageDetector = require("i18next-browser-languagedetector");
const nexti18next = require("next-i18next");
const { useSSR, Trans } = require("react-i18next");

const config = require("./config");

const initPromise = i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(config)
  .then(() => i18n);

exports.initPromise = initPromise;

module.exports = {
  ...nexti18next,
  initPromise,
  useSSR,
  Trans,
};
