import React, { useEffect, useRef } from "react";
import { useRouter } from "next/router";
import cookie from "js-cookie";
import dynamic from "next/dynamic";
import gtm from "react-gtm-module";

import { Layout } from "components/Layout/Layout";
import { isServer } from "utils/runtime";
import { pathnames } from "pathnames";
import { useUser } from "modules/user";

import { AppContainerProps } from "./App.types";
import { useSignInRedirectParams } from "./useSignInRedirectParams";

const isBetaModalTurnedOffByQueryParam = () => {
  if (isServer) return false;

  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get("nobetamodal") === "true";
};

const BetaModal = dynamic(() => import("modules/Beta/Modal"), {
  ssr: false,
});

const PageWrapper = ({
  Component,
  catalogItems,
  catalogId,
  pageProps,
}: Pick<AppContainerProps, "Component" | "catalogId" | "catalogItems" | "pageProps">) => {
  const { disableLayout = false, getLayout } = Component.layoutConfig || {};

  if (disableLayout) {
    return <Component {...pageProps} catalogId={catalogId} />;
  }

  if (getLayout) {
    return <Layout catalogItems={catalogItems}>{getLayout(<Component {...pageProps} catalogId={catalogId} />)}</Layout>;
  }

  return (
    <Layout catalogItems={catalogItems}>
      <Component {...pageProps} catalogId={catalogId} />
    </Layout>
  );
};

export function AppContainer({ Component, pageProps = {}, disableRender, catalogId, catalogItems }: AppContainerProps) {
  const gtmInitRef = useRef(false);
  const { locale, asPath, route } = useRouter();
  const user = useUser();

  const { setRedirectTo } = useSignInRedirectParams();

  if (disableRender) {
    return null;
  }

  const shouldShowBetaModal =
    !isServer && window.innerWidth >= 768 && cookie.get("w3intro") !== "1" && !isBetaModalTurnedOffByQueryParam();

  useEffect(() => {
    switch (route) {
      case pathnames.home.en:
        setRedirectTo(""); // Clear redirectTo on home
        break;
      case pathnames.signIn.en:
      case pathnames.signUp.en:
      case pathnames.passwordReset.en:
        // Don't change redirectTo on auth pages
        break;
      default:
        setRedirectTo(`/${locale}${asPath}`);
    }
  }, [asPath, locale, route]);

  useEffect(() => {
    if (!gtmInitRef.current && !user.isLoading) {
      gtmInitRef.current = true;
      gtm.initialize({ gtmId: process.env.GTM_ID });
    }
  }, [user.isLoading]);

  return (
    <>
      <PageWrapper Component={Component} pageProps={pageProps} catalogItems={catalogItems} catalogId={catalogId} />
      {shouldShowBetaModal && <BetaModal />}
    </>
  );
}
