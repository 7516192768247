import React, { DetailedHTMLProps, HTMLAttributes } from "react";
import styled from "styled-components";
import { secondaryColor } from "modules/DesignSystem/colors";

interface Props extends Omit<DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>, "ref"> {
  value: number | string;
}

const BadgeStyled = styled.span`
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  color: #160829;
  padding: 1px 5px;
  border-radius: 7px;
  background-color: ${secondaryColor};
`;

const Badge: React.FC<Props> = ({ value = "…", ...props }) => <BadgeStyled {...props}>{value}</BadgeStyled>;

export default styled(Badge)``;
