import styled, { css } from "styled-components";

import Icon from "modules/DesignSystem/components/Icon";
import LogoBase from "modules/DesignSystem/components/Logo";
import { MEDIA_QUERY } from "modules/DesignSystem/breakpoint";

export const Close = styled(Icon)`
  color: #979797;
  cursor: pointer;
  justify-self: flex-end;
`;

export const Content = styled.div<{ size: number }>`
  height: 100%;
  display: grid;
  font-size: 16px;
  gap: 12px;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(${({ size }) => size}, auto) 1fr;
  overflow: auto;
  padding: 16px 0;
`;

export const Header = styled.div`
  align-items: center;
  border-bottom: 1px solid rgba(187, 183, 197, 0.2);
  display: grid;
  gap: 10px 26px;
  grid-template-columns: repeat(2, auto);
  justify-content: space-between;
  padding-bottom: 12px;
`;

export const Logo = styled(LogoBase)`
  height: 22px;
  width: auto;
`;

export const Root = styled.div<{ open: boolean }>`
  background: #160829;
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: 0;
  overflow: hidden;
  padding: 16px 16px 0;
  position: fixed;
  top: 0;
  ${({ open }) => css`
    transform: ${open ? "translateX(0)" : "translateX(-101%)"};
  `}
  transition: transform .2s ease;
  width: 100%;
  z-index: 100;

  @media screen and ${MEDIA_QUERY.md} {
    display: none;
  }
`;

export const titleStyles = css`
  align-items: start;
  color: #fff;
  display: grid;
  flex-basis: 100%;
  font-weight: 700;
  gap: 16px;
  grid-column: 1/3;
  grid-template-columns: repeat(2, auto);
  justify-content: start;
  line-height: 24px;
  padding: 4px 0;
  text-decoration: none;

  ${Icon} {
    color: "inherit";
  }
`;
