import styled from "styled-components";

import ButtonPrimitive from "modules/DesignSystem/components/ButtonPrimitive";

export const Form = styled.form`
  display: flex;
  align-items: center;
  flex: 1;
  position: relative;
`;

export const Input = styled.input`
  outline: none;
  width: 100%;
  height: var(--input-height);
  background: none;
  border: 1px solid var(--search-input-border-color);
  border-radius: 8px;
  color: #fff;
  padding: 8px calc(var(--input-height) + 32px) 8px 16px;

  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
`;

export const ButtonsWrapper = styled.div`
  position: absolute;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ClearButton = styled(ButtonPrimitive)`
  color: #979797;
  margin-right: 8px;

  svg {
    display: flex;
  }
`;

export const SearchButton = styled(ButtonPrimitive)`
  border-radius: 8px;
  height: var(--input-height);
  width: var(--input-height);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--search-button-color);
  background-color: var(--search-button-bg-color);

  &[disabled] {
    pointer-events: none;
  }
`;
