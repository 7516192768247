export const config = {
  columns: [
    {
      title: "footer.customer_service",
      expandable: true,
      links: [
        { href: "https://audioteka.com/en/buyers-guide", text: "footer.how_to_buy" },
        { href: "https://web.audioteka.com/en/l/tos", text: "footer.terms_and_conditions" },
        { href: "https://audioteka.com/en/privacy-policy", text: "footer.privacy_policy" },
        {
          href: "https://web.audioteka.com/en/l/tos-audioteka-plus",
          text: "footer.audioteka_plus_tos",
        },
        {
          href: "https://web.audioteka.com/en/l/tos-club",
          text: "footer.audioteka_club_tos",
        },
        { route: "helpCenter", text: "footer.help_center_contact" },
      ],
    },
    {
      title: "footer.useful_links",
      expandable: true,
      links: [
        { href: "https://audioteka.com/en/static/zapowiedzi", text: "footer.upcoming_releases" },
        { href: "https://welcome.audioteka.com/", text: "footer.choose_language" },
        { href: "https://cards.audioteka.com/", text: "footer.gift_cards" },
        { href: "https://pages.audioteka.com/en/aktywuj-karte", text: "footer.activate_card" },
        {
          href: "https://pages.audioteka.com/en/kupuj-w-audiotece/?_footer",
          text: "footer.coupons_and_subscriptions",
        },
        { href: "https://audioteka.com/en/static/praca", text: "footer.careers" },
      ],
    },
    {
      title: "footer.applications",
      apps: {
        android: "https://web.audioteka.com/en/l/android",
        ios: "https://web.audioteka.com/en/l/ios",
      },
      links: [{ href: "https://audioteka.com/en/applications", text: "footer.all_apps" }],
    },
  ],
  social: [
    {
      href: "https://www.facebook.com/audioteka",
      icon: "facebook",
    },
    {
      href: "https://www.youtube.com/user/audiotekapl",
      icon: "youtube",
    },
    {
      href: "https://www.instagram.com/audioteka/",
      icon: "instagram",
    },
    {
      href: "https://twitter.com/audioteka",
      icon: "twitter",
    },
  ],
  copy: `Copyright © 2008-${new Date().getFullYear()} Audioteka Group Sp. z o.o.`,
};
