import styled from "styled-components";

import { MEDIA_QUERY } from "modules/DesignSystem/breakpoint";
import Container from "modules/DesignSystem/components/Container";
import { topBarConfig } from "consts";

import toasterConfig from "../../config";

const Toaster = styled(Container)`
  align-items: end;
  display: flex;
  flex-direction: column;
  gap: ${toasterConfig.gap}px;
  left: 50%;
  pointer-events: none;
  position: fixed;
  top: ${topBarConfig.mobileHeight + 18}px;
  transform: translateX(-50%);
  z-index: 100;

  @media screen and ${MEDIA_QUERY.md} {
    top: ${topBarConfig.desktopHeight + 18}px;
  }
`;

export default Toaster;
