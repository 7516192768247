import { pathnames } from "pathnames";

export function buildLink(
  pathname: keyof typeof pathnames,
  locale: string,
  params?: Record<string, string | string[]>,
  fullUrl = false
) {
  const url = new URL(`/${locale}${pathnames[pathname][locale]}`, process.env.SITE_URL);

  if (params) {
    Object.entries(params).forEach(([key, value]) => {
      if (url.pathname.includes(`[${key}]`)) {
        url.pathname = url.pathname.replace(`[${key}]`, String(value));
      } else if (Array.isArray(value)) {
        value.forEach((subValue) => {
          url.searchParams.append(key, subValue);
        });
      } else {
        url.searchParams.set(key, value);
      }
    });
  }

  if (!url.pathname.endsWith("/")) {
    url.pathname += "/";
  }

  if (fullUrl) {
    return url.href;
  }

  return url.pathname + url.search;
}
