/* eslint-disable no-use-before-define */
import { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from "axios";

import { getDeviceID } from "modules/App";
import AxiosClient from "utils/AxiosClient";
import { isServer } from "utils/runtime";

class AudiotekaApi extends AxiosClient {
  private static instance: AudiotekaApi;

  private constructor() {
    super({
      baseURL: new URL("/v2", process.env.API_URL).toString(),
      withCredentials: true,
      headers: {
        "X-Audioteka-Device": `AudiotekaWeb/${process.env.BUILD_VERSION} Web/3.0 (Browser;${
          isServer ? "NEXT_SERVER" : window.navigator.userAgent
        })`,
      },
    });

    this.initializeInterceptors();
  }

  static getInstance(): AudiotekaApi {
    if (!AudiotekaApi.instance) {
      AudiotekaApi.instance = new AudiotekaApi();
    }

    return AudiotekaApi.instance;
  }

  async sendCommand<Payload, Response>(payload: Payload) {
    return this.post<Payload, Response>({ url: "/commands" }, payload);
  }

  private async refreshToken() {
    return this.sendCommand<
      { name: "RefreshTokenForWeb"; device_id: ReturnType<typeof getDeviceID> },
      { expires_at: string }
    >({ name: "RefreshTokenForWeb", device_id: getDeviceID() });
  }

  private initializeInterceptors = () => {
    this.axiosInstance.interceptors.response.use(
      (response: AxiosResponse) => response,
      async (error: AxiosError) => {
        const originalRequest: InternalAxiosRequestConfig & { retry?: boolean } = error.config;

        if (error.response.status === 401 && !originalRequest.retry) {
          // set retry to avoid getting into an infinite loop if the server returns a 401 again
          originalRequest.retry = true;

          try {
            await this.refreshToken();
            return this.axiosInstance(originalRequest);
          } catch (refreshError) {
            return Promise.reject(refreshError);
          }
        }

        return Promise.reject(error);
      }
    );
  };
}

export const audiotekaApi = AudiotekaApi.getInstance();
