import React from "react";
import dynamic from "next/dynamic";
import cookie from "js-cookie";

import { isServer } from "utils/runtime";

import { STATUS_KEY } from "./consts";

const MobileAppBannerComp = dynamic(() => import("./MobileAppBanner"), {
  ssr: false,
});

const MobileAppBanner = () => {
  if (isServer) {
    return null;
  }

  const status = cookie.get(STATUS_KEY);

  if (status === "closed") {
    return null;
  }

  const isAndroid = /android/i.test(window.navigator.userAgent);
  const isIOS = /(ipad|iphone|ipod)/i.test(window.navigator.userAgent);

  if (!isAndroid && !isIOS) {
    return null;
  }

  return <MobileAppBannerComp platform={isAndroid ? "android" : "ios"} />;
};

export default MobileAppBanner;
