import React, { type SVGProps } from "react";

type IconName =
  | "home"
  | "download-alt"
  | "apple"
  | "window"
  | "cover"
  | "list"
  | "person"
  | "people"
  | "devices"
  | "document"
  | "facebook"
  | "youtube"
  | "instagram"
  | "twitter"
  | "add-to-cart"
  | "check"
  | "mobile-phone"
  | "hand-with-hearts"
  | "fire"
  | "touchscreen"
  | "headphones-alt"
  | "promo"
  | "share"
  | "kid"
  | "envelope"
  | "lock"
  | "eye"
  | "eye-crossed"
  | "star"
  | "star-half"
  | "star-empty"
  | "list-border"
  | "play"
  | "heart"
  | "heart-border"
  | "bookshelf"
  | "history"
  | "gear"
  | "logout"
  | "user"
  | "user-alt"
  | "close"
  | "shopping-cart"
  | "menu"
  | "search"
  | "trash"
  | "hamburger"
  | "club-logo"
  | "chevron"
  | "popular"
  | "recommended";

export const NewIcon = ({ icon, ...props }: SVGProps<SVGSVGElement> & { icon: IconName }) => {
  return (
    <svg {...props}>
      <use href={`/sprite-09082024.svg#${icon}`} />
    </svg>
  );
};
