import styled, { keyframes } from "styled-components";

const wave = keyframes`
  0% {
    transform: translateX(-100%);
  }
  60% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(100%);
  }
`;

const Skeleton = styled.div`
  background: rgba(0, 0, 0, 0.25);
  overflow: hidden;
  position: relative;
  border-radius: 4px;

  &::after {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    position: absolute;
    animation: ${wave} 1.6s linear 0.5s infinite;
    transform: translateX(-100%);
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.08), transparent);
  }
`;

export default Skeleton;
